import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lui-file-download-link',
  templateUrl: './file-download-link.component.html',
  styleUrl: './file-download-link.component.less'
})
export class FileDownloadLinkComponent {

  @Input()
  fileName: string;

  @Output()
  downloadClicked: EventEmitter<void> = new EventEmitter();

  download(): void {
    this.downloadClicked.emit();
  }
}
