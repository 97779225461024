<nz-spin [nzSpinning]="loading" *ngIf="objectForm">
    <form nz-form [formGroup]="objectForm" class="login-form">
        <nz-form-item>
            <nz-form-label nzRequired [nzSm]="8" [nzXs]="24" nzFor="accountNr">Konta numurs</nz-form-label>
            <nz-form-control luiValidationError="accountNr" [nzSm]="16" [nzXs]="24">
                <input type="text" nz-input id="accountNr" formControlName="accountNr" placeholder="Konta numurs" />
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label nzFor="subAccountNr" [nzSm]="8" [nzXs]="24">Subkonta nr.</nz-form-label>
            <nz-form-control luiValidationError="subAccountNr" [nzSm]="16" [nzXs]="24">
                <input type="text" nz-input id="subAccountNr" formControlName="subAccountNr" placeholder="Subkonta nr." />
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label nzRequired [nzSm]="8" [nzXs]="24" nzFor="bank">Banka</nz-form-label>
            <nz-form-control luiValidationError="bankId" [nzSm]="16" [nzXs]="24">
                <nz-select nzShowSearch nzPlaceHolder="Banka" formControlName="bankId">
                    <nz-option *ngFor="let bank of banks$ | async" [nzLabel]="bank.name" [nzValue]="bank.id"></nz-option>
                </nz-select>
            </nz-form-control>
        </nz-form-item>
        <div></div>
        <div *nzModalFooter>
            <button nz-button nzType="default" [disabled]="loading" (click)="cancel()">Atcelt</button>
            <button nz-button nzType="primary" [disabled]="loading" (click)="validateAndSave()">Saglabāt</button>
        </div>
    </form>
</nz-spin>
