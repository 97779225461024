import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Bank } from '@lui/shared/models/bank.model';
import { BaseService } from './base.service';

@Injectable({ providedIn: 'root' })
export class BanksService extends BaseService<Bank> {
    constructor(httpClient: HttpClient) {
        super(httpClient, '/api/v1/banks/');
    }
}
