import { Address } from './address.model';
import { BaseModel } from './base-model.model';
import { Company } from './company.model';
import { UsersDepartment } from './users-department.model';

export interface Department extends BaseModel {
    companyId: number;
    companyName: string;
    address: Address;
    name: string;
    prefix: string;
    phone: string;
    departmentEmail: string;
    salaryEmail: string;
    city: string;
    vzdBranch: string;
    defaultDiscountPercent: number;
    isActive: boolean;

    company: Company;

    usersDepartments: UsersDepartment[];
}

export interface DepartmentGroup {
    groupName: string;
    departments: Department[];
}
