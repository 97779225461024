import { Address } from './address.model';
import { BaseModel } from './base-model.model';

export interface Contact extends BaseModel {
    name: string;
    surname: string;
    code: string;
    phone: string;
    contactEmail: string;
    notes: string;
    personTypeId: number;
    address: Address;
}
