import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';

import { NzImageService } from 'ng-zorro-antd/image';
import { CommandResult } from '@lui/shared/models/command-result.model';
import { BaseModel } from '@lui/shared/models';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { FileMetadata } from '@lui/shared/models/file.model';
import { BaseTableResult } from '@lui/shared/models/base-table-result.model';
import { BaseFilterModel } from '@lui/shared/models/base-filter-model.model';
import { saveAs } from 'file-saver';

export abstract class BaseService<T extends BaseModel> {
    constructor(protected httpClient: HttpClient, protected baseApiUrl: string) {}

    getAllServerSidePaginatedOrdered(
        pageIndex: number,
        pageSize: number,
        sortField: string | null,
        sortOrder: string | null,
    ): Observable<CommandResult<BaseTableResult<T>>> {
        let httpParams = new HttpParams().append('page', `${pageIndex}`);
        httpParams = httpParams.append('pageSize', `${pageSize}`);

        if (sortField) {
            httpParams = httpParams.append('orderField', `${sortField}`);
        }
        if (sortOrder) {
            httpParams = httpParams.append('orderDir', `${sortOrder}`);
        }

        return this.httpClient.get<CommandResult<BaseTableResult<T>>>(`${this.baseApiUrl}` + 'serverside', {
            params: httpParams,
        });
    }

    getAll(): Observable<CommandResult<T[]>> {
        return this.httpClient.get<CommandResult<T[]>>(this.baseApiUrl);
    }

    search(text: string): Observable<CommandResult<T[]>> {
        return this.httpClient.get<CommandResult<T[]>>(`${this.baseApiUrl}search/${text}`);
    }

    getSingle(id: number): Promise<CommandResult<T>> {
        return this.httpClient.get<CommandResult<T>>(this.baseApiUrl + id).toPromise();
    }

    create(obj: T | FormData): Promise<number> {
        return this.httpClient.post<number>(this.baseApiUrl, obj).toPromise();
    }

    update(id: number, obj: T): Promise<void> {
        obj.id = id;
        return this.httpClient.put<void>(this.baseApiUrl, obj).toPromise();
    }

    delete(id: number): Promise<void> {
        return this.httpClient.delete<void>(this.baseApiUrl + id).toPromise();
    }
}

export abstract class BaseServiceWithFilter<T extends BaseModel, TFilter extends BaseFilterModel> extends BaseService<T> {
    constructor(protected httpClient: HttpClient, protected baseApiUrl: string) {
        super(httpClient, baseApiUrl);
    }

    getAllServerSidePaginatedOrderedFiltered(
        pageIndex: number,
        pageSize: number,
        sortField: string | null,
        sortOrder: string | null,
        filterObj: TFilter,
    ): Observable<CommandResult<BaseTableResult<T>>> {
        let httpParams = new HttpParams().append('page', `${pageIndex}`).append('pageSize', `${pageSize}`);

        if (sortField) {
            httpParams = httpParams.append('orderField', `${sortField}`);
        }
        if (sortOrder) {
            httpParams = httpParams.append('orderDir', `${sortOrder}`);
        }

        if (filterObj) {
            Object.entries(filterObj).forEach(([key, value]) => {
                if (value) {
                    httpParams = httpParams.append(key, value.toString());
                }
            });
        }

        return this.httpClient.get<CommandResult<BaseTableResult<T>>>(`${this.baseApiUrl}` + 'serverside', {
            params: httpParams,
        });
    }
}

export abstract class BaseDownloadService<T extends BaseModel> extends BaseService<T> {
    constructor(protected httpClient: HttpClient, protected baseApiUrl: string, protected nzImageService: NzImageService) {
        super(httpClient, baseApiUrl);
    }

    protected handleFileDownloading(requestPromise: Promise<CommandResult<FileMetadata>>): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            requestPromise.then(
                (fileMetadata) => {
                    try {
                        const link = `${environment.apiBaseUrl}/api/v1/authorized-files/${fileMetadata.data.id}`;

                        if (fileMetadata.data.mimeType === 'image/jpeg' || fileMetadata.data.mimeType === 'image/png') {
                            const previewRef = this.nzImageService.preview([
                                {
                                    src: link,
                                },
                            ]);
                            previewRef.previewInstance.operations.push({
                                icon: 'download',
                                type: 'download',
                                onClick: () => {
                                    setTimeout(() => {
                                        window.open(`${link}/download`);
                                    });
                                },
                            });
                        } else {
                            setTimeout(() => {
                                window.open(link, '_blank');
                            });
                        }

                        resolve();
                    } catch (error) {
                        console.error('Couldnt save file');
                        reject(error);
                    }
                },
                (err: HttpErrorResponse) => {
                    console.error(err);
                    reject(err);
                },
            );
        });
    }

    protected handleGeneratedFileDownload(requestPromise: Promise<HttpResponse<Blob>>): Promise<void> {
        return requestPromise
            .then((response: HttpResponse<Blob>) => {
                const contentDisposition = response.headers.get('content-disposition');
                const fileName = this.extractFileNameFromContentDisposition(contentDisposition);
                saveAs(response.body, fileName);
            })
            .catch((error) => {
                console.error('Error during file download:', error);
                throw error;
            });
    }

    private extractFileNameFromContentDisposition(contentDisposition: string | null): string {
        if (!contentDisposition) {
            throw new Error('Content-Disposition header is missing');
        }
        const fileNameMatch = contentDisposition.match(/filename="?([^";]+)"?/);
        if (!fileNameMatch || fileNameMatch.length < 2) {
            throw new Error('Filename not found in Content-Disposition header');
        }
        return fileNameMatch[1].replace(/^\"+|\"+$/g, '');
    }
}

export abstract class BaseDownloadServiceWithFilter<T extends BaseModel, TFilter extends BaseFilterModel> extends BaseServiceWithFilter<T, TFilter> {
    constructor(protected httpClient: HttpClient, protected baseApiUrl: string, protected nzImageService: NzImageService) {
        super(httpClient, baseApiUrl);
    }

    protected handleFileDownloading(requestPromise: Promise<CommandResult<FileMetadata>>): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            requestPromise.then(
                (fileMetadata) => {
                    try {
                        const link = `${environment.apiBaseUrl}/api/v1/authorized-files/${fileMetadata.data.id}`;

                        if (fileMetadata.data.mimeType === 'image/jpeg' || fileMetadata.data.mimeType === 'image/png') {
                            const previewRef = this.nzImageService.preview([
                                {
                                    src: link,
                                },
                            ]);
                            previewRef.previewInstance.operations.push({
                                icon: 'download',
                                type: 'download',
                                onClick: () => {
                                    setTimeout(() => {
                                        window.open(`${link}/download`);
                                    });
                                },
                            });
                        } else {
                            setTimeout(() => {
                                window.open(link, '_blank');
                            });
                        }

                        resolve();
                    } catch (error) {
                        console.error('Couldnt save file');
                        reject(error);
                    }
                },
                (err: HttpErrorResponse) => {
                    console.error(err);
                    reject(err);
                },
            );
        });
    }

    protected handleGeneratedFileDownload(requestPromise: Promise<HttpResponse<Blob>>): Promise<void> {
        return requestPromise
            .then((response: HttpResponse<Blob>) => {
                const contentDisposition = response.headers.get('content-disposition');
                const fileName = this.extractFileNameFromContentDisposition(contentDisposition);
                saveAs(response.body, fileName);
            })
            .catch((error) => {
                console.error('Error during file download:', error);
                throw error;
            });
    }

    private extractFileNameFromContentDisposition(contentDisposition: string | null): string {
        if (!contentDisposition) {
            throw new Error('Content-Disposition header is missing');
        }
        const fileNameMatch = contentDisposition.match(/filename="?([^";]+)"?/);
        if (!fileNameMatch || fileNameMatch.length < 2) {
            throw new Error('Filename not found in Content-Disposition header');
        }
        return fileNameMatch[1].replace(/^\"+|\"+$/g, '');
    }
}
