import { ComponentFactoryResolver, Directive, Input, OnInit, Self, ViewContainerRef } from '@angular/core';
import { NzFormControlComponent } from 'ng-zorro-antd/form';
import { ValidationErrorComponent } from '../components/validation-error/validation-error.component';

@Directive({
    selector: '[luiValidationError]'
})
export class ValidationErrorDirective implements OnInit {

    @Input('luiValidationError')
    fieldName: string;

    constructor(private resolver: ComponentFactoryResolver,
        private viewContainerRef: ViewContainerRef,
        @Self() private nzFormControlComponent: NzFormControlComponent) {
    }

    ngOnInit(): void {
        const factory = this.resolver.resolveComponentFactory(ValidationErrorComponent);
        const component = this.viewContainerRef.createComponent(factory);
        component.instance.fieldName = this.fieldName;

        const errorTemplateRef = component.instance.getTemplateRef();
        this.nzFormControlComponent.nzErrorTip = errorTemplateRef;
    }
}
