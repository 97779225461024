import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Department, DepartmentGroup } from '@lui/shared/models';
import { BaseService } from './base.service';

@Injectable({ providedIn: 'root' })
export class DepartmentsService extends BaseService<Department> {
    constructor(httpClient: HttpClient) {
        super(httpClient, '/api/v1/departments/');
    }

    async getGroupedDepartments(): Promise<DepartmentGroup[]> {
        const departments = (await this.getAll().toPromise()).data;
        return this.createDepartmentGroups(departments);
    }

    async getAuthorizedGroupedDepartments(permission: any): Promise<DepartmentGroup[]> {
        const departments = await this.httpClient.get<Department[]>(`${this.baseApiUrl}authorized/${permission}`).toPromise();
        return this.createDepartmentGroups(departments);
    }

    private createDepartmentGroups(departments: Department[]) {
        const departmentGroup = [];
        departments.forEach(d => {
            let group = departmentGroup.find(gd => gd.groupName === d.companyName);
            if (group) {
                group.departments.push(d);
                return;
            }
            group = {
                groupName: d.companyName,
                departments: [d]
            } as DepartmentGroup;
            departmentGroup.push(group);
        });
        return departmentGroup;
    }
}
