import { Injectable, Injector, Type } from '@angular/core';
import { BaseModel, ModelType } from '@lui/shared/models';
import { BaseService } from './base.service';
import { ClientsService } from './clients.service';
import { ContactsService } from './contacts.service';

@Injectable({ providedIn: 'root' })
export class ServiceFactory {
    private serviceMap: { type: ModelType, serviceType: any }[] =
        [{ type: ModelType.Client, serviceType: ClientsService },
        { type: ModelType.Contact, serviceType: ContactsService }];

    constructor(private injector: Injector) { }

    getService<T extends BaseModel>(modelType: ModelType): BaseService<T> {
        const serviceType = this.serviceMap.find(sm => sm.type === modelType);
        if (!serviceType) {
            throw new Error(`Service for ModelType ${modelType} was not found`);
        }
        return this.injector.get<BaseService<T>>(serviceType.serviceType);
    }
}
