import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ESignatureLocalStorageKeys } from '@lui/shared/models/e-signature-local-storage-keys.enum';

@Injectable({
    providedIn: 'root',
})
export class ESignatureGuard {
    constructor(private location: Location) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!localStorage.getItem(ESignatureLocalStorageKeys.FLOW)) {
            this.location.back();
            return false;
        }
        return true;
    }
}
