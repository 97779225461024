import { Control } from "ol/control";
import { Options } from "ol/control/Control";
import Map from "ol/Map";
import { MapMeassureService } from "./map.meassure.service";

export class ZoomToFitControl extends Control {

    mapMeasureService: MapMeassureService;
    btn: HTMLButtonElement;
    closeIcon: string;

    constructor(mapMeasureService: MapMeassureService) {
        const options = {} as Options;
        const button = document.createElement('button');

        const element = document.createElement('div');
        element.className = 'zoom-to-fit-control ol-unselectable ol-control';
        element.appendChild(button);

        super({
            element: element,
            target: options.target,
        });

        this.mapMeasureService = mapMeasureService;
        button.addEventListener('click', this.onZoomToFitClick.bind(this), false);

        button.innerHTML = '<svg width="1em" height="1em" viewBox="0 0 24 24" role="img" xmlns="http://www.w3.org/2000/svg" aria-labelledby="zoomInIconTitle" stroke="#FFFFFF" stroke-width="2" stroke-linecap="square" stroke-linejoin="miter" fill="none" color="#FFFFFF"> <title id="zoomInIconTitle">Zoom In</title> <path d="M4,20 L9.58788778,14.4121122"/> <path d="M14,16 C10.6862915,16 8,13.3137085 8,10 C8,6.6862915 10.6862915,4 14,4 C17.3137085,4 20,6.6862915 20,10 C20,13.3137085 17.3137085,16 14,16 Z"/> <path d="M16.6666667 10L11.3333333 10M14 7.33333333L14 12.6666667"/> </svg>';
        this.btn = button;
    }

    onZoomToFitClick() {
        const map = this.getMap();
        const source = this.mapMeasureService.measureSource;
        if (!source) {
            return;
        }

        map.getView().fit(source.getExtent(), {
            padding: [40, 40, 40, 40]
        });
    }
}
