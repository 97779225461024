import { UsersDepartment } from "./users-department.model";
import { BaseModel } from "./base-model.model";

export interface User extends BaseModel {
    username: string;
    userEmail: string;
    phone: string;
    name: string;
    code: string;
    surname: string;

    groupsId: number;
    groupsName: string;

    departmentId: number;
    departmentName: string;

    personTypeId: number;
    performerId: number;
    isActive: boolean;

    sex: string;
    notes: string;

    usersDepartments: UsersDepartment[]

    firstSaveTime: string;
}
