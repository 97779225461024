import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'eur',
  standalone: true
})
export class EurPipe implements PipeTransform {

  transform(sum: number): string {
    const sumToConvert = sum ? sum : 0;
    return sumToConvert.toFixed(2).toString() + ' EUR';
  }

}
