import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Account } from '@lui/shared/models';
import { BaseService } from './base.service';

@Injectable({ providedIn: 'root' })
export class AccountService extends BaseService<Account> {
    constructor(httpClient: HttpClient) {
        super(httpClient, '/api/v1/accounts/');
    }
}
