<nz-spin [nzSpinning]="loading" *ngIf="objectForm">
    <form nz-form [formGroup]="objectForm" class="login-form">
        <nz-form-item>
            <nz-form-label nzRequired [nzSm]="8" [nzXs]="24" nzFor="administrativeTerritoryId">Admin. terit.</nz-form-label>
            <nz-form-control luiValidationError="administrativeTerritoryId" [nzSm]="16" [nzXs]="24">
                <nz-select nzShowSearch nzPlaceHolder="Admin. terit." formControlName="administrativeTerritoryId">
                    <nz-option
                        *ngFor="let t of administrativeTerritories$ | async"
                        [nzLabel]="t.name + ' (' + t.code + ')'"
                        [nzValue]="t.id"
                    ></nz-option>
                </nz-select>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="street">Iela</nz-form-label>
            <nz-form-control luiValidationError="street" [nzSm]="16" [nzXs]="24">
                <input type="text" nz-input id="street" formControlName="street" placeholder="Iela" />
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label nzRequired nzFor="house" [nzSm]="8" [nzXs]="24">Māja</nz-form-label>
            <nz-form-control luiValidationError="house" [nzSm]="16" [nzXs]="24">
                <input type="text" nz-input id="house" formControlName="house" placeholder="Māja" />
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label nzFor="flat" [nzSm]="8" [nzXs]="24">Dzīvoklis</nz-form-label>
            <nz-form-control luiValidationError="flat" [nzSm]="16" [nzXs]="24">
                <input type="text" nz-input id="flat" formControlName="flat" placeholder="Dzīvoklis" />
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label nzFor="postalCode" [nzSm]="8" [nzXs]="24">Pasta indekss</nz-form-label>
            <nz-form-control luiValidationError="postalCode" [nzSm]="16" [nzXs]="24">
                <input type="text" nz-input id="postalCode" formControlName="postalCode" placeholder="Pasta indekss" />
            </nz-form-control>
        </nz-form-item>
        <div></div>
        <div *nzModalFooter>
            <button nz-button nzType="default" [disabled]="loading" (click)="cancel()">Atcelt</button>
            <button nz-button nzType="primary" [disabled]="loading" (click)="validateAndSave()">Saglabāt</button>
        </div>
    </form>
</nz-spin>
