import { Control } from "ol/control";
import { Options } from "ol/control/Control";
import Map from "ol/Map";
import { MapMeassureService } from "./map.meassure.service";

export class MapDrawControl extends Control {

    mapMeasureService: MapMeassureService;
    btn: HTMLButtonElement;
    drawing: boolean;
    drawIcon: string;
    closeIcon: string;

    constructor(mapMeasureService: MapMeassureService) {
        const options = {} as Options;
        const button = document.createElement('button');

        const element = document.createElement('div');
        element.className = 'draw-control ol-unselectable ol-control';
        element.appendChild(button);

        super({
            element: element,
            target: options.target,
        });
        this.mapMeasureService = mapMeasureService;
        button.addEventListener('click', this.onDrawingClick.bind(this), false);

        this.drawIcon = '<svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="edit" aria-hidden="true"><path d="M257.7 752c2 0 4-.2 6-.5L431.9 722c2-.4 3.9-1.3 5.3-2.8l423.9-423.9a9.96 9.96 0 000-14.1L694.9 114.9c-1.9-1.9-4.4-2.9-7.1-2.9s-5.2 1-7.1 2.9L256.8 538.8c-1.5 1.5-2.4 3.3-2.8 5.3l-29.5 168.2a33.5 33.5 0 009.4 29.8c6.6 6.4 14.9 9.9 23.8 9.9zm67.4-174.4L687.8 215l73.3 73.3-362.7 362.6-88.9 15.7 15.6-89zM880 836H144c-17.7 0-32 14.3-32 32v36c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-36c0-17.7-14.3-32-32-32z"></path></svg>';
        this.closeIcon = '<svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="close" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg>';
        button.innerHTML = this.drawIcon;
        this.btn = button;
    }

    onDrawingClick() {
        const map = this.getMap();
        this.drawing = !this.drawing;
        this.btn.innerHTML = this.drawing ? this.closeIcon : this.drawIcon;

        if (this.drawing) {
            this.startPolygonDrawing(map);
        } else {
            this.mapMeasureService.disable(true);
        }
    }

    private startPolygonDrawing(map: Map): void {
        map.updateSize();
        map.renderSync();
        this.mapMeasureService.enable(map, 'Polygon');
    }
}
