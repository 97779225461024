import { AbstractControl, ValidationErrors } from '@angular/forms';

export function atLeastOne(...fields: string[]) {
    return (fg: AbstractControl): ValidationErrors | null => {
        const err = fields.some(fieldName => {
            const field = fg.get(fieldName).value;
            if (typeof field === 'number') return field && field >= 0 ? true : false;
            if (typeof field === 'string') return field && field.length > 0 ? true : false;
        })
            ? null
            : ({ atLeastOne: true } as ValidationErrors);
        return err;
    };
}
