import { Component, OnDestroy } from '@angular/core';
import { ESignatureSuccessHandlerService } from './core/services/e-signature-success-handler.service';

@Component({
    selector: 'app-root',
    template: `<router-outlet></router-outlet>`,
    standalone: false
})
export class AppComponent implements OnDestroy {
    constructor(private eSignatureSeuccessHandler: ESignatureSuccessHandlerService) {
        this.eSignatureSeuccessHandler.init();
    }

    ngOnDestroy(): void {
        this.eSignatureSeuccessHandler.disable();
    }
}
