import { BaseModel } from './base-model.model';

export interface EstimatePositionType extends BaseModel {
    price: number;
    name: string;
    unit: string;
    ordersTypeId: number;
    ordersTypeName: string;
    workTypeId: number;
    workTypeName: string;
    phaseTypeId: number;
    scontractId: number;

    isDiscount: boolean;
    defaultAmount: number;
    estimatePercent: number;
    subemployerPercent: number;
    vzdPercent: number;
    transportPercent: number;

    isActive: boolean;
}
