import { Component, OnInit, Input, Type, Output, EventEmitter } from '@angular/core';
import { BaseModel, FieldError } from '@lui/shared/models';
import { BaseUpsertComponent } from '@lui/core/components/base-upsert-component';
import { BaseService } from '@lui/core/services';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
    selector: 'lui-upsert-field',
    templateUrl: './upsert-field.component.html',
    styleUrls: ['upsert-field.component.less']
})
export class UpsertFieldComponent implements OnInit {

    @Input()
    fieldId: string;

    @Input()
    fieldLabel: string;

    @Input()
    object: BaseModel;

    @Input()
    isRequired: boolean;


    _isSubmitPerformed: boolean;
    @Input()
    set isSubmitPerformed(val: boolean) {
        this._isSubmitPerformed = val;
        if (this.isRequired && this.isObjectEmpty) {
            this.validChange.emit(false);
        }
    }
    get isSubmitPerformed(): boolean {
        return this._isSubmitPerformed;
    }

    @Output()
    objectChange: EventEmitter<BaseModel> = new EventEmitter<BaseModel>();

    @Output()
    validChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input()
    upsertComponent: Type<BaseUpsertComponent<BaseModel, BaseService<BaseModel>>>;

    @Input()
    isEditMode: boolean;

    @Input()
    viewModel: any;

    _fieldErrors: FieldError[];
    @Input()
    set fieldErrors(value: FieldError[]) {
        this.formChanged = false;
        this._fieldErrors = value;
        this.validChange.emit(!this.hasError);
    }
    get fieldErrors(): FieldError[] {
        return this._fieldErrors;
    }

    formChanged: boolean = false;

    constructor(private modalService: NzModalService) { }

    ngOnInit() {
        if (this.isRequired && this.isObjectEmpty) {
            this.validChange.emit(false);
        }
    }

    async addObject(): Promise<void> {
        const modal = this.modalService.create({
            nzTitle: 'Pievienošana',
            nzContent: this.upsertComponent
        });

        modal.afterOpen.subscribe(() => {
            const component = modal.getContentComponent();
            component.initForm();
            component.returnObject = true;
            component.objectForm.markAsDirtyAndUpdateAll();
            component.mapErrorsToControls(this.getFieldErrors);
        });

        modal.afterClose.subscribe(async (obj: BaseModel) => {
            if (obj) {
                this.object = obj;
                this.objectChange.emit(this.object);
                this.formChanged = true;
                this.validChange.emit(true);
            }
        });
    }

    async editObject(): Promise<void> {
        const modal = this.modalService.create({
            nzTitle: 'Labošana',
            nzContent: this.upsertComponent
        });

        modal.afterOpen.subscribe(() => {
            const component = modal.getContentComponent();
            component.setEditMode(this.object);
            component.objectForm.markAsDirtyAndUpdateAll();
            component.mapErrorsToControls(this.getFieldErrors);
            component.returnObject = true;
        });

        modal.afterClose.subscribe(async (obj: BaseModel) => {
            if (obj) {
                this.object = obj;
                this.objectChange.emit(this.object);
                this.formChanged = true;
                this.validChange.emit(true);
            }
        });
    }

    get isObjectEmpty(): boolean {
        return Object.keys(this.object).length === 0;
    }

    isPassedObjectEmpty(object): boolean {
        return !object || Object.keys(object).length === 0;
    }

    get hasRequiredError(): boolean {
        return this.isRequired && this.isObjectEmpty && this.isSubmitPerformed;
    }

    get hasError(): boolean {
        return this.fieldErrors && this.fieldErrors.some(e => e.key.split('.')[0] === this.fieldId);
    }

    get getFieldErrors(): FieldError[] {
        if (!this.fieldErrors) {
            return [];
        }
        return this.fieldErrors
            .filter(e => e.key.split('.')[0] === this.fieldId)
            .map(fe => {
                return { key: fe.key.substring(fe.key.indexOf('.') + 1), error: fe.error };
            });
    }
}
