<nz-form-item>
    <nz-form-label [nzFor]="fieldId" [nzSm]="8" [nzXs]="24">{{ fieldLabel }}</nz-form-label>
    <nz-form-control [nzSm]="16" [nzXs]="24">
        <nz-input-group [nzAddOnAfter]="isSearchPerformed || object ? suffixActions : null" [nzCompact]="true">
            <nz-select
                nzShowSearch
                nzServerSearch
                nzPlaceHolder="Meklēt"
                [(ngModel)]="object"
                [nzShowArrow]="false"
                [ngModelOptions]="{ standalone: true }"
                [nzFilterOption]="nzFilterOption"
                (nzOnSearch)="search($event)"
                (ngModelChange)="selectionChanged($event)"
            >
                <nz-option *ngFor="let o of listOfOption" [nzLabel]="o.text" [nzValue]="o.value"> </nz-option>
            </nz-select>
        </nz-input-group>
    </nz-form-control>
    <ng-template #suffixActions>
        <nz-flex nzGap="small">
            <button nz-button [nzSize]="'small'" [nzType]="'primary'" *ngIf="isSearchPerformed" (click)="addObject()">
                <i nz-icon nzType="plus" nzTheme="outline"></i>
            </button>
            <button nz-button [nzType]="'secondary'" [nzSize]="'small'" *ngIf="object" (click)="editObject()">
                <i nz-icon nzType="edit" nzTheme="outline"></i>
            </button>
        </nz-flex>
    </ng-template>
</nz-form-item>
