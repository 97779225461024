import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Address } from '@lui/shared/models/address.model';
import { BaseService } from './base.service';

@Injectable({ providedIn: 'root' })
export class AddressService extends BaseService<Address> {
    constructor(httpClient: HttpClient) {
        super(httpClient, '/api/v1/addresses/');
    }
}
