import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ESignatureSuccessHandlerService {
    routeParamSubscription: Subscription;

    constructor(private router: Router, private activatedRoute: ActivatedRoute, private msgService: NzMessageService) {}

    init(): void {
        this.routeParamSubscription = this.activatedRoute.queryParams.subscribe((params) => {
            const eSignSuccessParamValue = params['eSignSuccess'];
            if (!eSignSuccessParamValue) return;

            const isSuccess = eSignSuccessParamValue === 'true';

            if (isSuccess) {
                this.router.navigate([], {
                    queryParams: { eSignSuccess: null }, // Remove the param
                    queryParamsHandling: 'merge', // Retain other existing params
                });
                this.msgService.success('Fails veiksmīgi parakstīts');
            } else {
                // handle failure, if needed. for now, failure is being handled in e-signature component
            }
        });
    }

    disable(): void {
        if (this.routeParamSubscription) this.routeParamSubscription.unsubscribe();
    }
}
