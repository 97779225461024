<div id="notfound">
    <div class="notfound">
        <div class="notfound-404">
            <h1>404</h1>
        </div>
        <h2>Lapa nav atrasta!</h2>
        <p>Lapa, kuru meklējat, neeksistē, tai ir mainīts nosaukums, vai arī tā īslaicīgi nav pieejama.</p>
        <a [routerLink]="'/dashboard'">Atpakaļ uz sākumu</a>
    </div>
</div>
