import { Account } from './account.model';
import { Contact } from './contact.model';

export interface Client extends Contact {
    clientEmail: string;
    isBlocked: boolean;
    clientTypeId: number;
    sex: string;
    fullName: string;
    account: Account;
}
