import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ESignatureService } from '@lui/core/services/e-signature.service';
import { ESignatureError } from '@lui/shared/models/e-signature-error.enum';
import { ESignatureFlow } from '@lui/shared/models/e-signature-flow.enum';
import { ESignatureLocalStorageKeys } from '@lui/shared/models/e-signature-local-storage-keys.enum';
import { ESignaturePostCallbackData } from '@lui/shared/models/e-signature-post-callback-data.model';
import { ESignatureStep } from '@lui/shared/models/e-signature-step.enum';
import { Subscription } from 'rxjs';

const signingExtension: string = require('../../../../package.json').eSignature.signingExtensionName;
const reqVersion: string = require('../../../../package.json').eSignature.requiredVersion;
const recVersion: string = require('../../../../package.json').eSignature.recommendedVersion;

declare var eparakstshwcrypto: any;

declare var SC_NO_IMPLEMENTATION: string;
declare var SC_FAILING_BACKEND: string;
declare var SC_NEW_VERSION: string;
declare var SC_USER_CANCEL: string;
declare var SC_NEW_VERSION_RECOMMENDED: string;

declare function getBackendError(type: string, resolvedVersion: string, requiredVersion: string, recommendedVersion: string | null): string | null;
@Component({
    selector: 'lui-e-signature',
    templateUrl: './e-signature.component.html',
    styleUrl: './e-signature.component.less',
    standalone: false
})
export class ESignatureComponent implements OnInit {
    routeChangeSubscription: Subscription;

    signingExtensionName: string;
    requiredVersion: string;
    recommendedVersion: string;

    state: string;
    code: string;
    flow: string;
    step: string;
    errorFromRedirectUrl: string;

    returnRoute: string = '/';

    loading: boolean = true;
    mobileSigning: boolean = false;

    errorMessages: string[] = [];
    noPluginError: boolean = false;
    reloadError: boolean = false;
    versionError: boolean = false;
    otherError: boolean = false;

    Steps = ESignatureStep;

    constructor(private router: Router, private route: ActivatedRoute, private eSignatureService: ESignatureService) {
        this.signingExtensionName = signingExtension;
        this.requiredVersion = reqVersion;
        this.recommendedVersion = recVersion;
    }

    async ngOnInit(): Promise<void> {
        this.readLocalStorageValues();

        this.routeChangeSubscription = this.route.queryParams.subscribe((params) => {
            this.state = params['state'];
            this.code = params['code'];
            this.errorFromRedirectUrl = params['error'];
        });

        if (this.errorFromRedirectUrl) {
            switch (this.errorFromRedirectUrl.toUpperCase()) {
                case ESignatureError.ACCESS_DENIED:
                    this.esignHandleError(ESignatureError.ACCESS_DENIED);
                    break;
                default:
                    this.esignHandleError(this.errorFromRedirectUrl);
                    break;
            }
            return;
        }

        const params = new HttpParams().append('code', this.code).append('state', this.state);

        if (this.flow === ESignatureFlow.MOBILE) {
            if (!this.state || !this.code) {
                this.esignHandleError(ESignatureError.MISSING_PARAM);
                return;
            }

            switch (this.step) {
                case ESignatureStep.INIT:
                    await this.mobileESignInitStep(params);
                    return;

                case ESignatureStep.SIGN:
                    await this.mobileESignSignStep(params);
                    return;
            }
        } else if (this.flow === ESignatureFlow.CARD) {
            if (!this.state) {
                this.esignHandleError(ESignatureError.MISSING_PARAM);
                return;
            }

            switch (this.step) {
                case ESignatureStep.INIT:
                    await this.cardESignInitStep(params);
                    return;

                case ESignatureStep.SIGN:
                    await this.cardESignSignStep();
                    return;
            }
        } else {
            this.esignHandleError(ESignatureError.FLOW_VALUE_NOT_RECOGNIZED);
            return;
        }
    }

    reloadPage(): void {
        location.reload();
    }

    private readLocalStorageValues(): void {
        this.flow = localStorage.getItem(ESignatureLocalStorageKeys.FLOW);
        if (!this.flow) {
            this.esignHandleError(ESignatureError.NO_FLOW_VALUE);
            return;
        }

        if (this.flow === ESignatureFlow.MOBILE) this.mobileSigning = true;

        this.step = localStorage.getItem(ESignatureLocalStorageKeys.STEP);
        if (!this.step) {
            this.esignHandleError(ESignatureError.NO_STEP_VALUE);
            return;
        }

        if (localStorage.getItem(ESignatureLocalStorageKeys.RETURN_ROUTE)) {
            this.returnRoute = localStorage.getItem(ESignatureLocalStorageKeys.RETURN_ROUTE);
        }
    }

    private async mobileESignInitStep(params: HttpParams): Promise<void> {
        try {
            const callbackResult = await this.eSignatureService.eSignatureCallbackGet(params);
            if (callbackResult && callbackResult.data.redirectUrl) {
                localStorage.setItem(ESignatureLocalStorageKeys.STEP, ESignatureStep.SIGN);
                window.open(callbackResult.data.redirectUrl, '_self');
                return;
            } else {
                this.esignHandleError(ESignatureError.NO_REDIRECT_URL);
                return;
            }
        } catch (err) {
            this.loading = false;
            this.otherError = true;
            this.eSignatureService.closeActiveSigningSession();
            console.error(err);
            if (err.error?.errors) {
                const errors: string[] = [];
                err.error.errors.forEach((error) => {
                    errors.push(error);
                });
                this.updateErrors(errors);
                return;
            }

            this.updateErrors(['Notikusi kļūda. Lūdzu, mēģiniet vēlreiz']);
            return;
        }
    }

    private async mobileESignSignStep(params: HttpParams): Promise<void> {
        try {
            const callbackResult = await this.eSignatureService.eSignatureCallbackGet(params);
            this.eSignatureService.clearESignatureLocalStorageValues();
            if (callbackResult && callbackResult.data.redirectUrl) {
                window.open(callbackResult.data.redirectUrl, '_self');
                return;
            }
            this.router.navigateByUrl('/?eSignSuccess=true');
            return;
        } catch (err) {
            this.loading = false;
            this.otherError = true;
            this.eSignatureService.closeActiveSigningSession();
            console.error(err);
            if (err.error?.errors) {
                const errors: string[] = [];
                err.error.errors.forEach((error) => {
                    errors.push(error);
                });
                this.updateErrors(errors);
                return;
            }

            this.updateErrors(['Notikusi kļūda. Lūdzu, mēģiniet vēlreiz']);
            return;
        }
    }

    private async cardESignInitStep(params: HttpParams): Promise<void> {
        try {
            const callbackResult = await this.eSignatureService.eSignatureCallbackGet(params);
            if (callbackResult && callbackResult.data.redirectUrl) {
                localStorage.setItem(ESignatureLocalStorageKeys.STEP, ESignatureStep.SIGN);
                window.open(callbackResult.data.redirectUrl, '_self');
                return;
            } else {
                this.esignHandleError(ESignatureError.NO_REDIRECT_URL);
                return;
            }
        } catch (err) {
            this.loading = false;
            this.otherError = true;
            this.eSignatureService.closeActiveSigningSession();
            console.error(err);
            if (err.error?.errors) {
                const errors: string[] = [];
                err.error.errors.forEach((error) => {
                    errors.push(error);
                });
                this.updateErrors(errors);
                return;
            }

            this.updateErrors(['Notikusi kļūda. Lūdzu, mēģiniet vēlreiz']);
            return;
        }
    }

    private async cardESignSignStep(): Promise<void> {
        this.checkForExtension()
            .then((): void => {
                this.checkBackendAndInitializeSigning();
            })
            .catch((): void => {
                this.esignHandleError(SC_NO_IMPLEMENTATION);
                return;
            });
    }

    private checkBackendAndInitializeSigning(): void {
        eparakstshwcrypto.getBackendInfo().then(
            async (response: any): Promise<void> => {
                const backendError: string = getBackendError(response.type, response.version, this.requiredVersion, this.recommendedVersion);
                if (backendError == null) {
                    await this.cardSign();
                    return;
                } else {
                    this.esignHandleError(backendError);
                    return;
                }
            },
            async (): Promise<void> => {
                this.loading = false;
                this.otherError = true;
                this.updateErrors(['Notikusi kļūda. Lūdzu, mēģiniet vēlreiz']);
                this.eSignatureService.closeActiveSigningSession();
            },
        );
    }

    private async cardSign() {
        try {
            // get signing certificate from eID card
            var signCertResponse = await eparakstshwcrypto.getCertificate({ lang: 'lv', operation: 'sign' });

            // get digest data from API
            const signDigestRequestData = {
                signCertificate: signCertResponse.hex,
            } as ESignaturePostCallbackData;
            const signDigestRequestParams = new HttpParams().append('state', this.state);
            var digestData = (await this.eSignatureService.eSignatureCallbackPost(signDigestRequestData, signDigestRequestParams)).data;

            // sign digest data to get signature
            const hash = { type: 'SHA', hex: this.base64ToHex(digestData.data.digest) };
            var signatureResponse = await eparakstshwcrypto.sign(signCertResponse, hash, { lang: 'lv', operation: 'sign' });

            // get authentication certificate for final signing
            var authCertResponse = await eparakstshwcrypto.getCertificate({ lang: 'lv', operation: 'auth' });

            // send signature and authentication certificate to API to finalize signing
            const finalizeSigningRequestData = {
                authCertificate: authCertResponse.hex,
                signature: signatureResponse.hex,
            } as ESignaturePostCallbackData;
            const finalizeSigningRequestParams = new HttpParams().append('state', this.state);
            const finalizeSigningResult = await this.eSignatureService.eSignatureCallbackPost(
                finalizeSigningRequestData,
                finalizeSigningRequestParams,
            );
            this.eSignatureService.clearESignatureLocalStorageValues();
            if (finalizeSigningResult && finalizeSigningResult.data.redirectUrl) {
                window.open(finalizeSigningResult.data.redirectUrl, '_self');
                return;
            }
            this.router.navigateByUrl('/?eSignSuccess=true');
            return;
        } catch (err) {
            this.loading = false;
            this.otherError = true;
            this.eSignatureService.closeActiveSigningSession();
            console.error(err);
            if (err.error?.errors) {
                const errors: string[] = [];
                err.error.errors.forEach((error) => {
                    errors.push(error);
                });
                this.updateErrors(errors);
                return;
            } else if (err.message) {
                this.updateErrors([`Notikusi kļūda. Lūdzu, mēģiniet vēlreiz. Papildus info: ${err.message}`]);
                return;
            }

            this.updateErrors(['Notikusi kļūda. Lūdzu, mēģiniet vēlreiz']);
            return;
        }
    }

    private esignHandleError(error: string): void {
        this.loading = false;
        this.eSignatureService.closeActiveSigningSession();
        if (error != SC_USER_CANCEL) {
            if (error === SC_NO_IMPLEMENTATION) {
                this.noPluginError = true;
                this.updateErrors(['eParaksts pārlūkprogrammas spraudnis nav atrasts. Aicinām lejupielādēt eParaksts pārlūkprogrammas spraudni']);
            } else if (error === SC_FAILING_BACKEND) {
                this.reloadError = true;
                this.updateErrors(['Nepieciešams pārlādēt lapu']);
            } else if (error === SC_NEW_VERSION) {
                this.versionError = true;
                this.updateErrors([
                    'Šī eParaksta spraudņa versija ir novecojusi. Lai turpinātu, lūdzu atjaunojiet programmas eParakstītājs 3.0 versiju',
                ]);
            } else if (error === SC_NEW_VERSION_RECOMMENDED) {
                this.versionError = true;
                this.updateErrors(['Ir pieejama jaunāka eParaksta spraudņa versija. Aicinām atjaunot programmas eParakstītājs 3.0 versiju']);
            } else if (
                error === ESignatureError.MISSING_PARAM ||
                error === ESignatureError.NO_REDIRECT_URL ||
                error === ESignatureError.NO_STEP_VALUE ||
                error === ESignatureError.NO_FLOW_VALUE ||
                error === ESignatureError.FLOW_VALUE_NOT_RECOGNIZED
            ) {
                this.otherError = true;
                this.updateErrors([`Kļūda parakstoties. Lūdzu, mēģiniet vēlreiz. Papildus info: ${error}`]);
            } else {
                this.otherError = true;
                this.updateErrors([`Notikusi kļūda. Lūdzu, mēģiniet vēlreiz. Papildus info: ${error}`]);
            }
        }
    }

    private checkForExtension(timeout: number = 5000): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            const intervalTime = 500;
            let elapsedTime = 0;

            const interval = setInterval(() => {
                // Check if the extension is present
                if (window[this.signingExtensionName]) {
                    clearInterval(interval);
                    resolve();
                } else {
                    elapsedTime += intervalTime;
                    if (elapsedTime >= timeout) {
                        clearInterval(interval);
                        reject();
                    }
                }
            }, intervalTime);
        });
    }

    private updateErrors(errors: string[]): void {
        this.errorMessages = [];
        this.errorMessages.push(...errors);
    }

    private base64ToHex(base64String: string): string {
        // Decode base64 to a binary string
        const binaryString = atob(base64String);

        // Create a Uint8Array from the binary string
        const bytes = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        // Convert bytes to a hex string
        let hexString = '';
        for (const byte of bytes) {
            hexString += ('0' + byte.toString(16)).slice(-2);
        }

        return hexString;
    }
}
