<nz-form-item>
  <nz-form-label [nzFor]="fieldId"
                 [nzRequired]="isRequired"
                 [nzSm]="8"
                 [nzXs]="24">{{ fieldLabel }}</nz-form-label>
  <nz-form-control [nzSm]="16"
                   [nzXs]="24"
                   [nzErrorTip]="fieldErrorTip"
                   [nzValidateStatus]="!formChanged && (hasError || hasRequiredError) ? 'error' : 'success'">
    <nz-input-group [nzSuffix]="suffixActions">
      <input type="text"
             nz-input
             [id]="fieldId"
             disabled
             [ngModelOptions]="{standalone: true}"
             [ngModel]="viewModel"
             [placeholder]="fieldLabel" />
      <ng-template #fieldErrorTip
                   let-control>
        <span *ngIf="hasError">Kāds no objekta laukiem ir kļūdains</span>
        <span *ngIf="hasRequiredError">Jānorāda obligāti</span>
      </ng-template>
    </nz-input-group>
  </nz-form-control>
  <ng-template #suffixActions>
    <button nz-button
            [nzSize]="'small'"
            [nzType]="'primary'"
            *ngIf="!isEditMode"
            (click)="addObject()">
      <i nz-icon
         nzType="plus"
         nzTheme="outline"></i></button>
    <button nz-button
            [nzType]="'secondary'"
            [nzSize]="'small'"
            *ngIf="viewModel"
            (click)="editObject()">
      <i nz-icon
         nzType="edit"
         nzTheme="outline"></i>
    </button>
  </ng-template>
</nz-form-item>
