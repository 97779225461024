<div>
    <form #notesForm="ngForm" nz-form [nzLayout]="'vertical'">
        <nz-form-item>
            <nz-form-label nzRequired nzFor="notes">Piezīmes/apraksts (min 5 simboli, maksimāli 128 simboli)</nz-form-label>
            <nz-form-control luiValidationError="notes">
                <textarea required rows="4" maxlength="128" minlength="5" nz-input id="notes" name="notes" placeholder="Piezīmes/apraksts" [(ngModel)]="notes"></textarea>
            </nz-form-control>
        </nz-form-item>
    </form>
</div>
<div *nzModalFooter>
    <button nz-button nzType="default" (click)="cancel()">Atcelt</button>
    <button [disabled]="!notesForm.valid" nz-button nzType="primary" (click)="submitAndReturn()">Saglabāt</button>
</div>
