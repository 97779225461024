import { UntypedFormGroup } from '@angular/forms';

export class LuidaisFormGroup extends UntypedFormGroup {

    markAsDirtyAndUpdateAll(): void {
        for (const i in this.controls) {
            if (this.controls.hasOwnProperty(i)) {
                this.controls[i].markAsDirty();
                this.controls[i].updateValueAndValidity();
            }
        }
    }
}
