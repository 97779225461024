import { Classifier } from './classifier.model';
import { BaseModel } from './base-model.model';
import { Client } from './client.model';
import { Contact } from './contact.model';
import { Department } from './department.model';
import { OrdersType } from './order-type.model';
import { File } from './file.model';
import { MapImage } from '../components/map/map-image.model';

export interface Estimate extends BaseModel {
    departmentId: number;
    department: Department;
    date: string;
    place: string;
    name: string;
    number: string;
    duration: number;
    expirationDate: string;
    notes: string;
    privateNotes: string;
    cadastreNumber: string;
    discountPercent: number;

    hasBorderArgue: boolean;
    hasBorderInadequacy: boolean;

    clientId: number;
    clientName: string;
    client: Client;

    contactId: number;
    contactName: string;
    contact: Contact;

    ordersTypeId: number;
    orderTypeName: string;

    ownershipTypeId: number;
    ownershipTypeName: string;

    area: number;
    length: number;
    numberOf: number;

    statusId: number;
    statusName: string;

    advancePaymentTypeId: number;
    advancePaymentTypeName: string;

    addressId: number;
    address: any[];

    hasRestrictedAccess: boolean;
    availableForUsers: number[];

    estimateInfo: Classifier[];

    estimatePositions: EstimatePosition[];
    estimateTotals: EstimateTotals;
    ordersType: OrdersType;

    attachments: File[];
    mapImages: MapImage[];

    description: string;
    usersId: number;

    isHidden: boolean;

    defaultPrintoutTemplateId: number;
}

export interface EstimatePosition extends BaseModel {
    customName: string;
    unit: string;
    price: number;
    amount: number;
    term: string;
    discountSumWithoutVat: number;
    sumWithoutVat: number;

    isTransportPosition: boolean;
    isDiscount: boolean;
    estimatePercent: number;
    subemployerPercent: number;
    vzdPercent: number;
    transportPercent: number;
    workTypeId: number;
    estimatePositionTypeId: number;
}

export interface EstimateTotals {
    totalDistance: number;
    vzdSumWithoutVat: number;
    estimateSumWithoutVat: number;
    subemployerSumWithoutVat: number;
    
    discountSumWithoutVat: number;
    
    campaignDiscountPercents: number;
    campaignSumWithoutVat: number;

    inadequacyPercent: number;
    inadequacySumWithoutVat: number;
    
    sumWithoutVat: number;
    vatPercent: number;
    sumVat: number;
    sumWithVat: number;
}
