import { Control } from "ol/control";
import { Options } from "ol/control/Control";
import Map from "ol/Map";
import { MapMeassureService } from "./map.meassure.service";

export class MeasureTooltipControl extends Control {

    mapMeasureService: MapMeassureService;
    btn: HTMLButtonElement;
    showMeassureTooltip: boolean;
    drawIcon: string;
    closeIcon: string;

    constructor(mapMeasureService: MapMeassureService) {
        const options = {} as Options;
        const button = document.createElement('button');

        const element = document.createElement('div');
        element.className = 'meassure-tooltip-control ol-unselectable ol-control';
        element.appendChild(button);

        super({
            element: element,
            target: options.target,
        });

        this.mapMeasureService = mapMeasureService;
        this.showMeassureTooltip = this.mapMeasureService.showMeassureTooltip;
        button.addEventListener('click', this.onToggleMeassureClick.bind(this), false);

        if (this.showMeassureTooltip) {
            button.classList.add('tooltip-enabled');
        }
        button.innerText = 'Ha';
        this.btn = button;
    }

    onToggleMeassureClick() {
        const map = this.getMap();
        this.showMeassureTooltip = !this.showMeassureTooltip;
        if (this.showMeassureTooltip) {
            this.btn.classList.add('tooltip-enabled');
        } else {
            this.btn.classList.remove('tooltip-enabled');
        }
        this.btn.blur();
        this.mapMeasureService.toggleTooltip(this.showMeassureTooltip);
    }
}
