import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User } from '@lui/shared/models/user.model';
import { BaseService } from './base.service';
import { UsersDepartment } from '@lui/shared/models/users-department.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UsersService extends BaseService<User> {
    constructor(protected httpClient: HttpClient) {
        super(httpClient, '/api/v1/users/');
    }

    delete(id: number): Promise<void> {
        throw new Error('Not allowed');
    }

    getPermissions(userId: number): Promise<UsersDepartment[]> {
        return this.httpClient
            .get<UsersDepartment[]>(`${this.baseApiUrl}${userId}/permissions`)
            .toPromise();
    }

    setPermission(userId: number, departmentId: number, permissionId: number): Promise<number> {
        return this.httpClient
            .post<number>(`${this.baseApiUrl}${userId}/departments/${departmentId}/permissions/${permissionId}`, null)
            .toPromise();
    }

    removePermission(userId: number, departmentId: number, permissionId: number): Promise<void> {
        return this.httpClient
            .delete<void>(`${this.baseApiUrl}${userId}/departments/${departmentId}/permissions/${permissionId}`)
            .toPromise();
    }

    getUsersForRestriction(): Observable<User[]> {
        return this.httpClient
            .get<User[]>(`${this.baseApiUrl}for-restriction`);
    }

    isPersonCodeUnique(code: string, id: number): Observable<boolean> {
        return this.httpClient.get<boolean>(`${this.baseApiUrl}${code}/unique/${id}`);
    }

    isUsernameUnique(username: string, id: number): Observable<boolean> {
        return this.httpClient.get<boolean>(`${this.baseApiUrl}${username}/username-unique/${id}`);
    }
}
