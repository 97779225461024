import { Component, OnInit, Type } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup, AbstractControlOptions } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';

import { ClassifierService, ContactsService } from '@lui/core/services';
import { Contact } from '@lui/shared/models/contact.model';
import { Classifier, ClassifierType, PersonTypes } from '@lui/shared/models';
import { UpsertAddressComponent } from '../upsert-address/upsert-address.component';
import { Address } from '@lui/shared/models/address.model';
import { BaseUpsertComponent } from '@lui/core/components/base-upsert-component';
import { Observable } from 'rxjs/internal/Observable';
import { startWith } from 'rxjs/operators';
import { AsyncValidatorService } from '@lui/core/validators/async-validator.service';
import { atLeastOne } from '@lui/core/validators/at-least-one.validator';
import { NzMessageService } from 'ng-zorro-antd/message';
import { PersonCodeType } from '@lui/core/validators/person-code-type.enum';
import { codeValidator } from '@lui/core/validators/code.validator';

@Component({
    selector: 'lui-upsert-contact',
    templateUrl: 'upsert-contact.component.html',
})
export class UpsertContactComponent extends BaseUpsertComponent<Contact, ContactsService> implements OnInit {
    personTypes$: Observable<Classifier[]>;

    upsertAddressComponent: Type<UpsertAddressComponent> = UpsertAddressComponent;

    address: Address = {} as Address;

    isAddressValid: boolean = false;

    isSubmitPerformed: boolean = false;

    personTypes: typeof PersonTypes = PersonTypes;
    selectedPersonType: PersonTypes;

    initialCode: string;

    constructor(
        private modal: NzModalRef,
        private classifierService: ClassifierService,
        private contactsService: ContactsService,
        private asyncValidatorService: AsyncValidatorService,
        messageService: NzMessageService,
        private fb: UntypedFormBuilder,
    ) {
        super(contactsService, messageService);

        // set modal texts
        this.saveSuccessText = 'Kontakts saglabāts!';
    }

    async ngOnInit(): Promise<void> {
        this.personTypes$ = this.classifierService.getClassifiers(ClassifierType.PersonTypes);
    }

    createForm(): UntypedFormGroup {
        return this.fb.group({
            name: [null, [Validators.required, Validators.maxLength(32)]],
            surname: [null, [Validators.required, Validators.maxLength(128)]],
            code: [null, [Validators.maxLength(13), codeValidator]],
            phone: [null, [Validators.maxLength(32)]],
            contactEmail: [null, [Validators.maxLength(128), Validators.email]],
            notes: [null, [Validators.maxLength(2000)]],
            personTypeId: [this.personTypes.Physical, [Validators.required]],
        });
    }

    createAndFillForm(): UntypedFormGroup {
        return this.fb.group({
            name: [this.originalObject.name, [Validators.required, Validators.maxLength(32)]],
            surname: [this.originalObject.surname, [Validators.required, Validators.maxLength(128)]],
            code: [this.originalObject.code, [Validators.maxLength(13), codeValidator]],
            phone: [this.originalObject.phone, [Validators.maxLength(32)]],
            contactEmail: [this.originalObject.contactEmail, [Validators.maxLength(128)]],
            notes: [this.originalObject.notes, [Validators.maxLength(2000)]],
            personTypeId: [this.originalObject.personTypeId, [Validators.required]],
        });
    }

    async setEditMode(contact: Contact): Promise<void> {
        super.setEditMode(contact);
        this.address = contact.address;
    }

    afterInit(): void {
        this.objectForm.validator = atLeastOne('phone', 'contactEmail');
        this.initialCode = !this.originalObject ? null : this.originalObject.code;
        const codeControl = this.objectForm.get('code');
        this.objectForm
            .get('personTypeId')
            .valueChanges.pipe(startWith(this.isEditMode ? this.originalObject.personTypeId : this.personTypes.Physical))
            .subscribe((value) => {
                this.selectedPersonType = value;
                if (value) {
                    codeControl.setAsyncValidators(
                        this.asyncValidatorService.personCodeValidator(
                            this.initialCode,
                            !this.originalObject?.id ? 0 : this.originalObject.id,
                            PersonCodeType.Contact,
                        ),
                    );
                } else {
                    codeControl.clearAsyncValidators();
                }
                codeControl.markAsDirty();
                codeControl.updateValueAndValidity();
            });
    }

    async save(): Promise<void> {
        const contact = <Contact>this.objectForm.getRawValue();
        
        if (!this.isAddressEmpty) {
            contact.address = this.address;
        }

        if (this.isEditMode) {
            await this.contactsService.update(this.objectId, Object.assign(this.originalObject, contact));
            this.modal.close(true);
            this.loading = false;
            return;
        }
        await this.contactsService.create(contact);
    }

    async validateAndSave(): Promise<void> {
        this.isSubmitPerformed = true;
        if (!this.isAddressEmpty && !this.isAddressValid) {
            return;
        }
        super.validateAndSave();
    }

    async submitAndReturn(val: boolean | Contact): Promise<void> {
        if (typeof val === 'boolean') {
            this.modal.close(val);
            return;
        }
        val.address = this.address; 
        if(this.isEditMode) { // update object if is edit mode, because submitAndReturn is used in form controls, where user can add new value or edit existing one. 
            await this.contactsService.update(this.objectId, Object.assign(this.originalObject, val));
        }
        this.modal.close(val);
    }

    cancel(): void {
        this.modal.destroy();
    }

    get isAddressEmpty(): boolean {
        return !this.address || Object.keys(this.address).length === 0;
    }
}
