import { EventEmitter, Injectable } from '@angular/core';
import { BaseDownloadServiceWithFilter } from './base.service';
import { WorkTime } from '@lui/shared/models/work-time.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { last, lastValueFrom } from 'rxjs';
import { StartStopWorkTimeRequest } from '@lui/shared/models/start-stop-work-time-request.model';
import { CommandResult } from '@lui/shared/models';
import { WorkTimeFilter } from '@lui/shared/models/work-time-filter.model';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { StopWorkTimerModalComponent } from '@lui/shared/components';
import { NzImageService } from 'ng-zorro-antd/image';

@Injectable({
    providedIn: 'root',
})
export class WorkTimesService extends BaseDownloadServiceWithFilter<WorkTime, WorkTimeFilter> {
    activeWorkTime: WorkTime;

    activeWorkTimeChanged: EventEmitter<WorkTime> = new EventEmitter<WorkTime>();

    constructor(protected httpClient: HttpClient, protected imageService: NzImageService, private modalService: NzModalService) {
        super(httpClient, '/api/v1/work-times/', imageService);
    }

    async getActiveWorkTime(): Promise<CommandResult<WorkTime>> {
        return lastValueFrom(this.httpClient.get<CommandResult<WorkTime>>(`${this.baseApiUrl}active`));
    }

    async startWorkTimer(requestModel: StartStopWorkTimeRequest): Promise<CommandResult<WorkTime>> {
        return lastValueFrom(this.httpClient.post<CommandResult<WorkTime>>(`${this.baseApiUrl}start`, requestModel));
    }

    async stopWorkTimer(requestModel: StartStopWorkTimeRequest): Promise<void> {
        return lastValueFrom(this.httpClient.put<void>(`${this.baseApiUrl}stop`, requestModel));
    }

    async getWorkTimeIsActive(workTimeId: number): Promise<boolean> {
        return lastValueFrom(this.httpClient.get<boolean>(`${this.baseApiUrl}is-active/${workTimeId}`));
    }

    async downloadWorkTimeReport(filterObj: WorkTimeFilter): Promise<void> {
        let httpParams = new HttpParams();
        if (filterObj) {
            Object.entries(filterObj).forEach(([key, value]) => {
                if (value) {
                    httpParams = httpParams.append(key, value.toString());
                }
            });
        }
        return this.handleGeneratedFileDownload(
            lastValueFrom(
                this.httpClient.get<Blob>(`${this.baseApiUrl}report`, { params: httpParams, responseType: 'blob' as 'json', observe: 'response' }),
            ),
        );
    }

    setActiveWorkTime(workTime: WorkTime): void {
        this.activeWorkTime = workTime;
        this.activeWorkTimeChanged.emit(this.activeWorkTime);
    }

    async createNotesModal(workTime: WorkTime): Promise<NzModalRef<StopWorkTimerModalComponent>> {
        if (!(await this.getWorkTimeIsActive(workTime.id))) {
            const confirmModal: NzModalRef = this.modalService.confirm({
                nzTitle: 'Šī laika uzskaite jau ir apturēta!',
                nzCancelText: null,
                nzOkText: 'Aizvērt',
            });

            confirmModal.afterClose.subscribe(() => {
                this.setActiveWorkTime(null);
            });
            return null;
        }

        return this.modalService.create({
            nzTitle: 'Piezīmju/apraksta ievadīšana',
            nzContent: StopWorkTimerModalComponent,
        });
    }
}
