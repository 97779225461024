import { Component, Input } from '@angular/core';

@Component({
    selector: 'lui-true-false-table-icon',
    template: `
        <i nz-icon *ngIf="value" nzType="check-circle" nzTheme="twotone" nzTwotoneColor="#52c41a"></i>
        <i nz-icon *ngIf="!value" nzType="close-circle" nzTheme="twotone" nzTwotoneColor="#c41a1a"></i>
    `,
})
export class TrueFalseTableIconComponent {
    @Input()
    value: boolean = false;
}
