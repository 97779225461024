import { BaseFilterModel } from './base-filter-model.model';

export interface DocumentFilter extends BaseFilterModel {
    searchText: string;
    startDate: string;
    endDate: string;
    sentAndUnsigned: boolean;
    markedSignableAndNotSent: boolean;
    signed: boolean;
    documentTypeId: number;
}
