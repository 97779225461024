import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseDownloadService } from './base.service';
import { CommandResult, OrdersType } from '@lui/shared/models';
import { lastValueFrom } from 'rxjs';
import { FileMetadata } from '@lui/shared/models/file.model';
import { NzImageService } from 'ng-zorro-antd/image';

@Injectable({ providedIn: 'root' })
export class OrdersTypesService extends BaseDownloadService<OrdersType> {
    constructor(httpClient: HttpClient,
        protected nzImageService: NzImageService) {
        super(httpClient, '/api/v1/orders-types/', nzImageService);
    }

    async downloadAttachment(ordersTypeId: number, attachment: string): Promise<void> {
        return this.handleFileDownloading(lastValueFrom(this.httpClient.get<CommandResult<FileMetadata>>(`${this.baseApiUrl}${ordersTypeId}/${attachment}`)));
    }

    async deleteAttachment(ordersTypeId: number, attachment: string): Promise<void> {
        return await lastValueFrom(this.httpClient.delete<void>(`${this.baseApiUrl}${ordersTypeId}/${attachment}`));
    }

    async addAttachment(ordersTypeId: number, formData: FormData): Promise<boolean> {
        return await lastValueFrom(this.httpClient.post<boolean>(`${this.baseApiUrl}${ordersTypeId}/attachments`, formData));
    }
}
