import { Directive, Input, OnChanges, OnDestroy, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthService } from '../../core/services';

@Directive({ selector: '[hasPermission]' })
export class HasPermissionDirective implements OnDestroy, OnChanges {
    private destroy$ = new Subject<void>();

    private hasView = false;
    private elseTemplate: TemplateRef<any> | null = null;
    private permissions: string[] = [];

    constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private authService: AuthService) {}

    /**
     * @description
     * This directive checks if the user has all of the specified permissions.
     * If the user has all permissions, the element will be shown; otherwise, it will be hidden.
     *
     * @param {Array} permissions An array of permissions to check against the user's permissions.
     *
     * @example
     * <div *hasPermission="['PERMISSION_1', 'PERMISSION_2']">
     *     This content is visible only if the user has both PERMISSION_1 and PERMISSION_2.
     * </div>
     */
    @Input() set hasPermission(permissions: string[]) {
        this.permissions = permissions;
        this.updateView();
    }

    /**
     * Accepts the `else` template to be shown when permissions are not met.
     */
    @Input() set hasPermissionElse(template: TemplateRef<any> | null) {
        this.elseTemplate = template;
        this.updateView();
    }

    private updateView(): void {
        const can = this.authService.userHasPermission(this.permissions);
        if (can && !this.hasView) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
        } else if (!can) {
            this.viewContainer.clear();
            this.hasView = false;

            if (this.elseTemplate) {
                this.viewContainer.createEmbeddedView(this.elseTemplate);
            }
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.updateView();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
