<div class="map"
     id="map"></div>
<div *nzModalFooter>

        <input type="text"
               nz-input
               class="img-comment"
               [(ngModel)]="comment"
               placeholder="Komentārs"
               id="img-comment"
               [ngModelOptions]="{standalone: true}" />

        <button nz-button
                nzType="default"
                (click)="cancel()">Atcelt</button>
        <button nz-button
                nzType="primary"
                (click)="addImage()">Pievienot attēlu</button>
</div>