import { BaseService } from '../services/base.service';
import { BaseModel } from '@lui/shared/models/base-model.model';

import { LuidaisFormGroup } from '@lui/shared/luidais-form-group';
import { UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { filter, take } from 'rxjs/operators';
import { ErrorCode, FieldError } from '@lui/shared/models/field-error.model';
import { NzMessageService } from 'ng-zorro-antd/message';

export abstract class BaseUpsertComponent<T extends BaseModel, TService extends BaseService<T>> {
    objectForm: LuidaisFormGroup;
    isEditMode: boolean;
    objectId: number;
    originalObject: T;
    loading: boolean;
    returnObject: boolean;
    fieldErrors: FieldError[];

    saveSuccessText: string = 'Objekts saglabāts!';
    createObjectSaveErrorText: string = 'Kļūda pievienojot objektu';
    updateObjectSaveErrorText: string = 'Kļūda labojot objektu';

    constructor(protected service: TService, protected messageService: NzMessageService) {}

    abstract createForm(): UntypedFormGroup;

    abstract createAndFillForm(obj: T): UntypedFormGroup;

    abstract submitAndReturn(val: boolean | T): void;

    abstract cancel(): void;

    initForm(): void {
        this.objectForm = new LuidaisFormGroup(this.createForm().controls);
    }

    initFormAndPrefill(obj: T): void {
        this.objectForm = new LuidaisFormGroup(this.createAndFillForm(obj).controls);
    }

    afterInit(): void {}

    setEditMode(obj: T): void {
        this.isEditMode = true;
        this.originalObject = obj;
        const formGroup = this.createAndFillForm(obj);
        this.objectForm = new LuidaisFormGroup(formGroup.controls);
        this.objectId = obj.id;

        this.afterInit();
    }

    async reloadData(): Promise<void> {
        const editableObj = await (await this.service.getSingle(this.originalObject.id)).data;
        this.setEditMode(editableObj);
    }

    async validateAndSave(): Promise<void> {
        this.objectForm.statusChanges
            .pipe(
                filter((status) => status !== 'PENDING'),
                take(1),
            )
            .subscribe(async (status) => {
                if (status !== 'VALID') {
                    return;
                }
                try {
                    this.loading = true;
                    if (this.returnObject) {
                        const obj = this.objectForm.getRawValue();
                        obj.id = this.objectId;
                        this.submitAndReturn(obj);
                        return;
                    }
                    await this.save();
                    this.messageService.success(this.saveSuccessText);
                    this.submitAndReturn(true);

                    this.loading = false;
                    return;
                } catch (ex) {
                    this.loading = false;
                    if (ex.error?.code === ErrorCode.FieldValidation) {
                        this.fieldErrors = ex.error.errors;
                        this.mapErrorsToControls(ex.error.errors);
                    }

                    if (ex.error?.errors) {
                        ex.error.errors.forEach((error) => {
                            this.messageService.error(error);
                        });
                        return;
                    }

                    this.messageService.error(this.isEditMode ? this.updateObjectSaveErrorText : this.createObjectSaveErrorText);
                }
            });

        this.objectForm.markAsDirtyAndUpdateAll();
    }

    async save(): Promise<void> {
        this.isEditMode
            ? await this.service.update(
                  this.objectId,
                  this.originalObject ? Object.assign(this.originalObject, this.objectForm.getRawValue()) : this.objectForm.getRawValue(),
              )
            : await this.service.create(this.objectForm.getRawValue());
    }

    mapErrorsToControls(errors: FieldError[]): void {
        errors.forEach((fieldError) => {
            const ctrl = this.objectForm.get(fieldError.key);
            if (ctrl) {
                const controlError: ValidationErrors = {};
                controlError[fieldError.key] = fieldError.error;
                ctrl.setErrors(controlError);
            }
        });
    }

    protected async withLoading(promise: Promise<any>): Promise<void> {
        this.loading = true;
        await promise;
        this.loading = false;
    }
}
