<div class="e-signature-layout">
    <nz-card>
        <div class="col align-items-center justify-content-center">
            <div class="row align-items-center justify-content-center w-60 mb-6">
                <img src="assets/img/logo_no_bg.png" alt="Luidais logo" class="logo" />
                <img *ngIf="mobileSigning" src="assets/img/e-sign/eMobile.png" alt="eID Eparaksts logo" class="ml-6 e-sign-logo" />
                <img *ngIf="!mobileSigning" src="assets/img/e-sign/eparaksts_eID_Karte.png" alt="eID Eparaksts logo" class="ml-6 e-sign-logo" />
            </div>

            <ng-container *ngIf="loading && mobileSigning">
                <div *ngIf="step === Steps.INIT" class="text-3xl text-center">
                    Notiek faila sagatavošana parakstīšanai, lūdzu uzgaidiet
                </div>
                <div *ngIf="step === Steps.SIGN" class="text-3xl text-center">Notiek parakstīšana, lūdzu uzgaidiet</div>
            </ng-container>
            <ng-container *ngIf="loading && !mobileSigning">
                <div class="text-3xl text-center">Pārliecinies, ka esi ievietojis eID karti karšu lasītājā</div>
            </ng-container>

            <div class="mt-4">
                <ng-container *ngIf="loading">
                    <nz-spin class="mt-3"></nz-spin>
                </ng-container>
                <ng-container *ngIf="!loading && errorMessages.length > 0">
                    <div class="col align-items-center justify-content-center">
                        <div *ngFor="let errorMsg of errorMessages" class="text-3xl text-center mb-3">
                            <span *ngIf="noPluginError || reloadError || otherError" class="text-danger">Kļūda:</span> {{ errorMsg }}
                        </div>

                        <a *ngIf="noPluginError" href="https://www.eparaksts.lv/lv/no_plugin" target="_self" nz-button nzType="link"
                            >Uz lejupielādes lapu</a
                        >
                        <a *ngIf="versionError" href="https://www.eparaksts.lv/atjauno_eParakstitajs3.0" target="_self" nz-button nzType="link"
                        >Uz lejupielādes lapu</a
                        >
                        <div class="row align-items-center justify-content-center gap-3 mt-2">
                            <button *ngIf="reloadError" nz-button (onClick)="reloadPage()" nzSize="large" nzType="primary">Pārlādēt</button>
                            <button nz-button [routerLink]="returnRoute" nzSize="large" nzType="primary">Atpakaļ</button>
                        </div>
                    </div>
                </ng-container>

            </div>
        </div>
    </nz-card>
</div>
