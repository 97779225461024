import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { PayoutType } from '@lui/shared/models';

@Injectable({ providedIn: 'root' })
export class PayoutTypesService extends BaseService<PayoutType> {
    constructor(httpClient: HttpClient) {
        super(httpClient, '/api/v1/payout-types/');
    }
}
