import { Component, OnInit, Input, Type, Output, EventEmitter } from '@angular/core';
import { BaseModel, ModelType } from '@lui/shared/models';
import { BaseUpsertComponent } from '@lui/core/components/base-upsert-component';
import { BaseService } from '@lui/core/services';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ServiceFactory } from '@lui/core/services/service.factory';

@Component({
    selector: 'lui-search-field',
    templateUrl: './search-field.component.html',
    styleUrls: ['search-field.component.less']
})
export class SearchFieldComponent implements OnInit {

    @Input()
    fieldId: string;

    @Input()
    fieldLabel: string;

    @Input()
    modelType: ModelType;

    _object: BaseModel;
    @Input()
    set object(val: BaseModel) {
        this._object = val;
        if (!this.objectToString) {
            return;
        }

        this.listOfOption = [];
        this.listOfOption.push({
            value: val,
            text: this.objectToString(val)
        });
    }
    get object(): BaseModel {
        return this._object;
    }

    @Input()
    objectToString: (model: BaseModel) => string;

    @Output()
    objectChange: EventEmitter<BaseModel> = new EventEmitter<BaseModel>();

    @Input()
    upsertComponent: Type<BaseUpsertComponent<BaseModel, BaseService<BaseModel>>>;

    formChanged: boolean = false;

    listOfOption: Array<{ value: BaseModel; text: string }> = [];
    nzFilterOption = () => true;
    service: BaseService<BaseModel>;
    isSearchPerformed: boolean;

    constructor(private modalService: NzModalService,
        private serviceFactory: ServiceFactory) { }

    ngOnInit(): void {
        this.service = this.serviceFactory.getService(this.modelType);
    }

    async search(text: string): Promise<void> {
        if (text.length < 3) {
            return;
        }
        const foundOptions = (await this.service.search(text).toPromise()).data;
        this.listOfOption = [];
        foundOptions.forEach(d => {
            this.listOfOption.push({
                value: d,
                text: this.objectToString(d)
            });
        });
        this.isSearchPerformed = true;
    }
    selectionChanged(newObject: BaseModel): void {
        this.objectChange.emit(newObject);
    }

    async addObject(): Promise<void> {
        const modal = this.modalService.create({
            nzTitle: 'Pievienošana',
            nzContent: this.upsertComponent
        });

        modal.afterOpen.subscribe(() => {
            const component = modal.getContentComponent();
            component.returnObject = true;
            component.initForm();
            component.afterInit();
            component.objectForm.markAsDirtyAndUpdateAll();
        });

        modal.afterClose.subscribe(async (obj: BaseModel) => {
            if (obj) {
                this.object = obj;
                this.objectChange.emit(this.object);
                this.formChanged = true;
                this.listOfOption.push({
                    value: this.object,
                    text: this.objectToString(this.object)
                });
            }
        });
    }

    async editObject(): Promise<void> {
        const modal = this.modalService.create({
            nzTitle: 'Labošana',
            nzContent: this.upsertComponent
        });

        const fullObjectData = this.object.id
            ? (await this.service.getSingle(this.object.id)).data
            : this.object;

        modal.afterOpen.subscribe(() => {
            const component = modal.getContentComponent();
            component.setEditMode(fullObjectData);
            component.returnObject = true;
            component.objectForm.markAsDirtyAndUpdateAll();
        });

        modal.afterClose.subscribe(async (obj: BaseModel) => {
            if (obj) {
                this.object = obj;
                this.objectChange.emit(this.object);
            }
        });
    }
}
