import { BaseModel } from "./base-model.model";
import { Client } from "./client.model";
import { Contract } from "./contract.model";
import { DocumentFile } from "./document-file.model";
import { DocumentType } from "./document-type.model";
import { Scontract } from "./scontract.model";

export interface Document extends BaseModel {
    documentTypeId: number;
    contractId: number;
    workId: number;
    scontractId: number;
    clientId: number;
    crmEventId: number;
    fuelReportId: number;
    payoutId: number;
    performerId: number;
    number: string;
    date: string;
    validUntil: string;
    notes: string;
    isGenerated: boolean;
    isPrintableInContract: boolean;
    isPublishableInPortal: boolean;
    isPublishableInProfile: boolean;
    lastNotificationSentTimestamp: string;
    signedTimestamp: string;
    isSignable: boolean;
    departmentId: number;
    client: Client;
    contract: Contract;
    scontract: Scontract;
    documentFiles: DocumentFile[];
    documentType: DocumentType;
}
