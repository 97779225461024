import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { WorkType } from '@lui/shared/models/work-type.model';
import { CommandResult } from '@lui/shared/models/command-result.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WorkTypesService extends BaseService<WorkType> {
    constructor(httpClient: HttpClient) {
        super(httpClient, '/api/v1/work-types/');
    }

    getWorkTypesForOrder(orderTypeId: number): Observable<CommandResult<WorkType[]>> {
        return this.httpClient.get<CommandResult<WorkType[]>>(`${this.baseApiUrl}order-type/${orderTypeId}`);
    }

    getEstimateItemsForOrder(orderTypeId: number): Promise<CommandResult<WorkType[]>> {
        return this.httpClient
            .get<CommandResult<WorkType[]>>(`${this.baseApiUrl}order-type/${orderTypeId}/include-estimate-items`).toPromise();
    }
}
