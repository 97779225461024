import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';

import { AccountService, BanksService } from '@lui/core/services';
import { Account, Bank } from '@lui/shared/models';
import { BaseUpsertComponent } from '@lui/core/components/base-upsert-component';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
    selector: 'lui-upsert-account',
    templateUrl: 'upsert-account.component.html',
})
export class UpsertAccountComponent extends BaseUpsertComponent<Account, AccountService> implements OnInit {
    banks$: Observable<Bank[]>;

    constructor(
        accountService: AccountService,
        messageService: NzMessageService,
        private modal: NzModalRef,
        private fb: UntypedFormBuilder,
        private banksService: BanksService,
    ) {
        super(accountService, messageService);
    }

    ngOnInit(): void {
        this.banks$ = this.banksService.getAll().pipe(map((b) => b.data));
    }

    createForm(): UntypedFormGroup {
        return this.fb.group({
            accountNr: [null, [Validators.required, Validators.maxLength(21)]],
            subAccountNr: [null, [Validators.maxLength(21)]],
            bankId: [null, [Validators.required]],
        });
    }
    createAndFillForm(account: Account): UntypedFormGroup {
        return this.fb.group({
            accountNr: [this.originalObject.accountNr, [Validators.required, Validators.maxLength(21)]],
            subAccountNr: [this.originalObject.subAccountNr, [Validators.maxLength(21)]],
            bankId: [this.originalObject.bankId, [Validators.required]],
        });
    }

    submitAndReturn(val: boolean | Account): void {
        this.modal.close(val);
    }

    cancel(): void {
        this.modal.destroy();
    }
}
