import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
import { ESignatureComponent } from './components/e-signature/e-signature.component';
import { AuthGuard } from './core/guards/auth-guard.service';
import { ESignatureGuard } from './core/guards/e-signature-guard.service';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('@lui/pages/pages.module').then((m) => m.PagesModule),
    },
    {
        path: 'auth',
        loadChildren: () => import('@lui/auth/auth.module').then((m) => m.AuthModule),
    },
    {
        path: 'e-signature',
        component: ESignatureComponent,
        canActivate: [ESignatureGuard, AuthGuard],
    },
    {
        path: '404',
        component: NotFoundComponent,
    },
    { path: '**', redirectTo: '/404' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
})
export class AppRoutingModule {}
