import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';

import { LuidaisFormGroup } from '@lui/shared/luidais-form-group';
import { AddressService, ClassifierService } from '@lui/core/services';
import { Address } from '@lui/shared/models/address.model';
import { BaseUpsertComponent } from '@lui/core/components/base-upsert-component';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AdministrativeTerritory } from '@lui/shared/models/administrative-territory.model';
import { AdministrativeTerritoriesService } from '@lui/core/services/administrative-territories.service';
import { map, Observable } from 'rxjs';

@Component({
    selector: 'lui-upsert-address',
    templateUrl: 'upsert-address.component.html',
})
export class UpsertAddressComponent extends BaseUpsertComponent<Address, AddressService> implements OnInit {
    administrativeTerritories$: Observable<AdministrativeTerritory[]>;

    constructor(
        addressService: AddressService,
        messageService: NzMessageService,
        private modal: NzModalRef,
        private fb: UntypedFormBuilder,
        private administrativeTerritoriesService: AdministrativeTerritoriesService,
    ) {
        super(addressService, messageService);

        // set modal texts
        this.saveSuccessText = 'Adrese saglabāta!';
        this.createObjectSaveErrorText = 'Kļūda pievienojot adresi';
        this.updateObjectSaveErrorText = 'Kļūda labojot adresi';
    }

    async ngOnInit(): Promise<void> {
        this.administrativeTerritories$ = await this.administrativeTerritoriesService.getAll().pipe(map((at) => at.data));
    }

    createForm(): UntypedFormGroup {
        return this.fb.group({
            street: [null, [Validators.maxLength(64)]],
            house: [null, [Validators.required, Validators.maxLength(64)]],
            flat: [null, [Validators.maxLength(16)]],
            postalCode: ['LV-', [Validators.maxLength(7)]],
            administrativeTerritoryId: [null, [Validators.required]],
        });
    }

    createAndFillForm(address: Address): UntypedFormGroup {
        return this.fb.group({
            street: [address.street, [Validators.maxLength(64)]],
            house: [address.house, [Validators.required, Validators.maxLength(64)]],
            flat: [address.flat, [Validators.maxLength(16)]],
            postalCode: [address.postalCode, [Validators.maxLength(7)]],
            administrativeTerritoryId: [address.administrativeTerritoryId, [Validators.required]],
        });
    }

    submitAndReturn(val: boolean | Address): void {
        this.modal.close(val);
    }

    cancel(): void {
        this.modal.destroy();
    }
}
