<div class="col justify-content-center align-items-center" *ngIf="timeSpent$ | async as t">
    <h3>Pasūtījums nr. {{ activeWorkTime.work.orders.number }}</h3>
    <div class="row align-items-center justify-content-center">
        <h1>
            <span>{{ formatTimePart(t.hoursElapsed) }}:</span>
            <span>{{ formatTimePart(t.minutesElapsed) }}:</span>
            <span>{{ formatTimePart(t.secondsElapsed) }}</span>
        </h1>
        <button nz-button nzType="primary" nzDanger class="ml-2" (click)="stopTimer()"><span nz-icon nzType="stop" nzTheme="outline"></span></button>
    </div>
</div>
