import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';

@Component({
    selector: 'lui-validation-error',
    template: `<ng-template #errorTip let-control>
        <ng-container *ngIf="control.hasError(fieldName)">
            {{ control.getError(fieldName) }}
        </ng-container>
        <br *ngIf="control.hasError(fieldName)" />
        <ng-container *ngIf="control.hasError('required')"> Ievadlauks ir obligāts </ng-container>
        <br *ngIf="control.hasError('required')" />
        <ng-container *ngIf="control.hasError('maxlength')">
            Vērtība nedrīkst būt garāka par {{ control.errors.maxlength.requiredLength }} simboliem. Vērības garums:
            {{ control.errors.maxlength.actualLength }}
        </ng-container>
        <br *ngIf="control.hasError('maxlength')" />
        <ng-container *ngIf="control.hasError('minlength')">
            Vērtībai ir jābūt garākai par {{ control.errors.minlength.requiredLength }} simboliem. Vērības garums:
            {{ control.errors.minlength.actualLength }}
        </ng-container>
        <br *ngIf="control.hasError('minlength')" />
        <ng-container *ngIf="control.hasError('pattern') || control.hasError('email')"> Nepareizs formāts </ng-container>
        <br *ngIf="control.hasError('pattern') || control.hasError('email')" />
        <ng-container *ngIf="control.hasError('min')"> Vērtībai jābūt lielākai par {{ control.errors.min.min }} </ng-container>
        <br *ngIf="control.hasError('min')" />
        <ng-container *ngIf="control.hasError('max')"> Vērtībai jābūt mazākai par {{ control.errors.max.max }} </ng-container>
    </ng-template>`,
})
export class ValidationErrorComponent {
    @ViewChild('errorTip', { static: true })
    tRef: TemplateRef<any>;

    @Input()
    fieldName: string;

    getTemplateRef(): TemplateRef<any> {
        return this.tRef;
    }
}
