import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthService } from '../../core/services';

@Directive({ selector: '[hasPermission]' })
export class HasPermissionDirective implements OnDestroy {
    private destroy$ = new Subject<void>();

    private hasView = false;

    constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private authService: AuthService) {}

    /**
     * @description
     * This directive checks if the user has all of the specified permissions.
     * If the user has all permissions, the element will be shown; otherwise, it will be hidden.
     * 
     * @param {Array} permissions An array of permissions to check against the user's permissions.
     * 
     * @example
     * <div *hasPermission="['PERMISSION_1', 'PERMISSION_2']">
     *     This content is visible only if the user has both PERMISSION_1 and PERMISSION_2.
     * </div>
     */
    @Input() set hasPermission(permissions: string[]) {
        const can = this.authService.userHasPermission(permissions);
        if (can && !this.hasView) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
        } else if (!can && this.hasView) {
            this.viewContainer.clear();
            this.hasView = false;
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
