import { BaseModel } from './base-model.model';
import { File } from './file.model';

export interface OrdersType extends BaseModel {
    name: string;
    duration: number;
    estimateExpiresAfter: number;
    hasBorderInadequacy: boolean;
    inadequacyPercent: number;
    deliverable: string;
    isCampaign: boolean;
    campaignDiscountPercent: number;
    isBorderArgueVisible: boolean;
    isCadastreNrRequired: boolean;
    printoutDescriptionHtml: string;
    defaultPrintoutTemplateId: number;
    isActive: boolean;
    savedById: number;

    files: File[];
}
