import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { AdministrativeTerritory } from '@lui/shared/models/administrative-territory.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class AdministrativeTerritoriesService extends BaseService<AdministrativeTerritory> {
    constructor(httpClient: HttpClient) {
      super(httpClient, '/api/v1/administrative-territories');
    }
}
