import { Injectable } from '@angular/core';

import TileLayer from 'ol/layer/Tile';
import BingMaps from 'ol/source/BingMaps';
import XYZ from 'ol/source/XYZ';
import olGroup from 'ol/layer/Group';
import TileWMS from 'ol/source/TileWMS';
import ImageWMS from 'ol/source/ImageWMS';
import TileState from 'ol/TileState';
import ImageArcGISRest from 'ol/source/ImageArcGISRest';
import ImageLayer from 'ol/layer/Image';

import { BaseLayer } from '@lui/shared/models/base-layer.model';
import { BaseLayersService } from '@lui/core/services/base-layers.service';
import { AuthService } from '@lui/core/services/auth.service';

interface LayerSettings {
    source: any;
    title: string;
    type?: string;
}

@Injectable({ providedIn: 'root' })
export class MapService {

    constructor(private baseLayersService: BaseLayersService,
        private authService: AuthService) { }

    async getBaseLayers(): Promise<olGroup[]> {

        const baseLayerConfigs = await this.baseLayersService.getActiveBaseLayers();
        const baseLayers: any[] = [];
        const overlayLayers: any[] = [];
        baseLayerConfigs.forEach(l => {
            if (l.layerType !== 'TileLayer' && l.layerType !== 'ImageLayer') {
                return;
            }
            let baseLayer = null;
            switch (l.sourceType) {
                case 'XYZ':
                    const xSource = new XYZ(JSON.parse(l.sourceJson));
                    const settings = {
                        source: xSource,
                        title: l.name
                    } as LayerSettings;
                    if (!l.isOverlay) {
                        settings.type = 'base';
                    }
                    baseLayer = new TileLayer(settings);
                    break;
                case 'BingMaps':

                    const settingsBing = {
                        source: new BingMaps(JSON.parse(l.sourceJson)),
                        title: l.name
                    } as LayerSettings;
                    if (!l.isOverlay) {
                        settingsBing.type = 'base';
                    }
                    baseLayer = new TileLayer(settingsBing);
                    break;
                case 'ImageArcGISRest':
                    const layerSource = JSON.parse(l.sourceJson);
                    const arcgisLayer = new ImageLayer(<any>{
                        source: new ImageArcGISRest({ ...layerSource, crossOrigin: 'anonymous', hidpi: false }),
                        title: l.name
                    });
                    baseLayer = arcgisLayer;
                    break;
                case 'TileWMS':

                    const tileLayerConfig = JSON.parse(l.sourceJson);
                    if (l.useJwt) {
                        tileLayerConfig.tileLoadFunction = (tile, src) => this.loadFunction(tile, src);
                    }

                    const tileSettingsWms = {
                        source: new TileWMS({
                            ...tileLayerConfig,
                            crossOrigin: 'anonymous'
                        }),
                        title: l.name
                    } as LayerSettings;
                    if (!l.isOverlay) {
                        tileSettingsWms.type = 'base';
                    }
                    baseLayer = new TileLayer(tileSettingsWms);
                    break;
                case 'ImageWMS':
                    baseLayer = this.getImageLayer(l);
                    break;
            }
            if (baseLayer) {
                baseLayer.setVisible(l.isEnabledByDefault);
                if (l.isOverlay) {
                    overlayLayers.push(baseLayer);
                } else {
                    baseLayers.push(baseLayer);
                }
                baseLayer.getSource().setAttributions(l.attribution);
            }
        });

        return [
            new olGroup(<any>{
                title: 'Base maps',
                layers: [...baseLayers],
                fold: true
            }),
            new olGroup(<any>{
                title: 'Overlay',
                layers: [...overlayLayers],
                fold: true
            })];
    }

    private getImageLayer(l: BaseLayer): ImageLayer<any> {
        const imageLayerConfig = JSON.parse(l.sourceJson);

        if (l.useJwt) {
            imageLayerConfig.imageLoadFunction = (img, src) => this.loadFunction(img, src);
        }

        const imageSettingsWms = {
            source: new ImageWMS({
                ...imageLayerConfig,
                crossOrigin: 'anonymous',
            }),
            title: l.name
        } as LayerSettings;
        if (!l.isOverlay) {
            imageSettingsWms.type = 'base';
        }
        return new ImageLayer(imageSettingsWms);
    }

    private loadFunction(tile, src) {
        const client = new XMLHttpRequest();
        client.open('GET', src);
        const JWT = `Bearer ${this.authService.token}`;
        client.setRequestHeader('Authorization', JWT);
        client.responseType = 'blob';
        client.addEventListener('loadend', function (evt) {
            const data = this.response;
            if (data !== undefined) {
                tile.getImage().src = URL.createObjectURL(data);
            } else {
                tile.setState(TileState.ERROR);
            }
        });
        client.send();
    }
}