import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, map, startWith, switchMap } from 'rxjs/operators';
import { ClientsService } from '../services/clients.service';
import { ContactsService } from '../services/contacts.service';
import { ClassifierService, PerformersService, UsersService } from '../services';
import { PersonCodeType } from './person-code-type.enum';

@Injectable({ providedIn: 'root' })
export class AsyncValidatorService {
    constructor(
        private clientsService: ClientsService,
        private contactsService: ContactsService,
        private usersService: UsersService,
        private performersService: PerformersService,
        private classifiersService: ClassifierService,
    ) {}

    personCodeValidator(initialValue: string, id: number, type: PersonCodeType = PersonCodeType.Client): AsyncValidatorFn {
        return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
            if (!control.value && !initialValue) {
                return of(null);
            }

            if (control.value === initialValue) {
                return of(null);
            }
            return control.valueChanges.pipe(
                debounceTime(700),
                distinctUntilChanged(),
                switchMap((val) => {
                    switch (type) {
                        case PersonCodeType.Client:
                            return this.clientsService.isPersonCodeUnique(val, id);
                        case PersonCodeType.Contact:
                            return this.contactsService.isPersonCodeUnique(val, id);
                        case PersonCodeType.User:
                            return this.usersService.isPersonCodeUnique(val, id);
                        case PersonCodeType.Performer:
                            return this.performersService.isPersonCodeUnique(val, id);
                    }
                }),
                map((isUnique) => (isUnique ? null : { error: true, duplicated: true })),
                first(),
            );
        };
    }

    usernameValidator(initialValue: string, id: number): AsyncValidatorFn {
        return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
            if (!control.value && !initialValue) {
                return of(null);
            }

            if (control.value === initialValue) {
                return of(null);
            }

            return control.valueChanges.pipe(
                switchMap((val) => {
                    return this.usersService.isUsernameUnique(val, id);
                }),
                map((isUnique) => (isUnique ? null : { error: true, duplicated: true })),
                first(),
            );
        };
    }

    classifiersOriginalLuidaisIdValidator(initialValue: number, parentId: number): AsyncValidatorFn {
        return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
            if (!control.value && !initialValue) {
                return of(null);
            }

            if (control.value === initialValue) {
                return of(null);
            }
            return control.valueChanges.pipe(
                debounceTime(700),
                distinctUntilChanged(),
                switchMap((val) => {
                    return this.classifiersService.isOriginalLuidaisIdUnique(parentId, val);
                }),
                map((isUnique) => (isUnique ? null : { error: true, duplicated: true })),
                first(),
            );
        };
    }
}
