import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { registerLocaleData, APP_BASE_HREF } from '@angular/common';
import en from '@angular/common/locales/en';
import lv from '@angular/common/locales/lv';
import { JwtModule } from '@auth0/angular-jwt';

import { NZ_DATE_CONFIG, NZ_I18N, lv_LV } from 'ng-zorro-antd/i18n';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from '@lui/shared/shared.module';
import { AuthErrorInterceptor } from './interceptors/auth.interceptor';
import { environment } from '@env/environment';
import { NotFoundComponent } from './not-found/not-found.component';
import { ESignatureComponent } from './components/e-signature/e-signature.component';

registerLocaleData(en);
lv[1][3] = '.'; // force decimal separator to be an dot (.) rather than comma (,) from LV locale
registerLocaleData(lv);
@NgModule({ declarations: [AppComponent, NotFoundComponent, ESignatureComponent],
    bootstrap: [AppComponent], imports: [SharedModule,
        RouterModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: () => {
                    return localStorage.getItem('access_token');
                },
                allowedDomains: [environment.apiBaseUrl],
            },
        })], providers: [
        { provide: NZ_I18N, useValue: lv_LV },
        {
            provide: NZ_DATE_CONFIG,
            useValue: {
                firstDayOfWeek: 1,
            },
        },
        { provide: APP_BASE_HREF, useValue: '/' },
        // {
        //   provide: HTTP_INTERCEPTORS,
        //   useClass: BaseUrlInterceptor,
        //   multi: true
        // },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthErrorInterceptor,
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}
