import { Component, OnDestroy, OnInit } from '@angular/core';

import html2canvas from 'html2canvas';
import Map from 'ol/Map';
import LayerSwitcher from 'ol-layerswitcher';
import * as Proj from 'ol/proj';
import proj4 from 'proj4';
import { register } from 'ol/proj/proj4.js';
import { Attribution, defaults as defaultControls } from 'ol/control';
import { MapService } from './map.service';
import View from 'ol/View';
import { MapMeassureService } from './map.meassure.service';
import { MapDrawControl } from './map-draw-control';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { MapImage } from './map-image.model';
import { MeasureTooltipControl } from './measure-tooltip-control';
import { ZoomToFitControl } from './zoom-to-fit-control';
import { FitControl } from './fit-control';

@Component({
    selector: 'lui-map',
    templateUrl: 'map.component.html',
    styleUrls: ['map.component.less']
})
export class MapComponent implements OnInit, OnDestroy {

    map: Map;
    polygon: string;
    comment: string;

    constructor(private mapService: MapService,
        private modal: NzModalRef,
        private mapMeasureService: MapMeassureService) { }

    async ngOnInit(): Promise<void> {
        const baseLayers = await this.mapService.getBaseLayers();

        proj4.defs('EPSG:3059',
            '+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9996 +x_0=500000 +y_0=-6000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');
        register(proj4);

        const attribution = new Attribution({
            collapsible: true
        });

        this.map = new Map({
            target: 'map',
            layers: baseLayers,
            view: new View({
                center: Proj.fromLonLat([23.9890828, 56.9713962]),
                zoom: 10,
            }),
            controls: defaultControls({ attribution: false }).extend([attribution]),
        });

        const layerSwitcher = new LayerSwitcher({ activationMode: 'click' });
        this.map.addControl(layerSwitcher);
        this.map.addControl(new MapDrawControl(this.mapMeasureService));
        this.map.addControl(new MeasureTooltipControl(this.mapMeasureService));
        this.map.addControl(new ZoomToFitControl(this.mapMeasureService));
        this.map.addControl(new FitControl(this.mapMeasureService));

        this.map.once('postrender',
            () => {
                this.map.updateSize();
                this.map.renderSync();
                layerSwitcher.showPanel();
            }
        );

        this.mapMeasureService.drawingFinished.subscribe(polygon => {
            this.polygon = polygon;
            if (!polygon) {
                return;
            }
            this.mapMeasureService.disable(false);
        });
    }

    async addImage(): Promise<void> {
        const base64Img = (await this.getMapCanvas()).toDataURL();
        this.modal.close({
            image: base64Img,
            polygon: this.polygon,
            comment: this.comment
        } as MapImage);
    }

    cancel(): void {
        this.modal.destroy();
    }

    ngOnDestroy(): void {
        this.mapMeasureService.disable(true);
    }

    private async getMapCanvas(): Promise<any> {
        const mapCanvas = document.createElement('canvas');
        const size = this.map.getSize();
        mapCanvas.width = size[0];
        mapCanvas.height = size[1];
        const mapContext = mapCanvas.getContext('2d');

        Array.prototype.forEach.call(
            document.querySelectorAll('.ol-layer canvas'),
            canvas => {
                if (canvas.width <= 0) {
                    return;
                }
                const opacity = canvas.parentNode.style.opacity;
                mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
                const transform = canvas.style.transform;
                // Get the transform parameters from the style's transform matrix
                const matrix = transform
                    .match(/^matrix\(([^\(]*)\)$/)[1]
                    .split(',')
                    .map(Number);
                // Apply the transform to the export map context
                CanvasRenderingContext2D.prototype.setTransform.apply(
                    mapContext,
                    matrix
                );
                mapContext.drawImage(canvas, 0, 0);
            }
        );

        const elems = document.querySelectorAll('.measure-layer');
        if (!elems[0]) {
            return mapCanvas;
        }
        const elem = elems[0].parentElement;

        for (let i = 0; i < elem.children.length; i++) {
            const child = elem.children[i];
            if (!child) {
                continue;
            }
            if (!child.classList.contains('measure-layer')) {
                elem.removeChild(child);
                i--;
            }
        }

        const c = await html2canvas(elem.parentElement, {
            backgroundColor: null
        });

        if (c.width > 0) {
            mapContext.drawImage(c, 0, 0);
        }


        return mapCanvas;
    }
}