import { AbstractControl, Validators } from '@angular/forms';
import { PersonTypes } from '@lui/shared/models/classifier.model';

export function codeValidator(formControl: AbstractControl): { [s: string]: boolean } {
    const regNrPersonTypes = [PersonTypes.Legal, PersonTypes.Municipality, PersonTypes.Goverment];

    if (!formControl.parent) {
        return null;
    }

    const personTypeId = formControl.parent.get('personTypeId').value;

    if (personTypeId === PersonTypes.Other) {
        return;
    }

    
    if (!formControl.value) {
        if (formControl.hasValidator(Validators.required)) { // if has no value and has required validator
            return { error: true, required: true };
        }
        return;
    }

    if (regNrPersonTypes.includes(personTypeId)) {
        const codeRegexp = new RegExp(/^(LV|lv)?\d{11}$/);
        if (!codeRegexp.test(formControl.value)) {
            return { error: true, pattern: true };
        }
    }
    if (personTypeId === PersonTypes.Physical
        && !isOldPersonCodeValid(formControl.value)
        && !isNewPersonCodeValid(formControl.value)) {
        return { error: true, pattern: true };
    }
}

function isNewPersonCodeValid(code: string): boolean {
    const regExp = /^32[0-9]{9}$/;
    return regExp.test(code);
}

function isOldPersonCodeValid(code: string): boolean {
    const reg1 = /^[0-9]{6}-[0-9]{5}$/;
    const reg2 = /^[0-9]{11}$/;
    if (!reg1.test(code) && !reg2.test(code)) {
        return false;
    }
    // Ja ir "-" personas kodā, tad to izņem
    if (reg1.test(code)) {
        code = code.substr(0, 6) + code.substr(7, 5);
    }
    // Notestē vai nav pārāk vecs vai pārāk jauns datums
    const year = (18 + parseInt(code.substr(6, 1), 10)) * 100 + parseInt(code.substr(4, 2), 10);
    const month = parseInt(code.substr(2, 2), 10);
    const day = parseInt(code.substr(0, 2), 10);
    const curdate = (year * 100 + month) * 100 + day;
    const today = new Date();

    const maxdate = (today.getFullYear() * 100 + today.getMonth()) * 100 + today.getDate();
    if (curdate < 18800101 || curdate > maxdate) {
        return false;
    }
    // Veidojam checksum
    const arrMult = new Array(1, 6, 3, 7, 9, 10, 5, 8, 4, 2);
    let checkSum = 0;
    for (let i = 0; i < 10; ++i) {
        checkSum += arrMult[i] * Number(code.substr(i, 1));
    }
    checkSum = ((1 - checkSum) % 11);
    if (checkSum < -1) {
        checkSum += 11;
    }
    // Pārbaudam checksum
    if (checkSum !== Number(code.substr(10, 1))) {
        return false;
    }
    // Ieliek atpakaļ "-" personas kodā
    code = code.substr(0, 6) + '-' + code.substr(6, 5);
    return true;
}
