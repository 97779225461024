import { BaseModel } from './base-model.model';
import { EstimatePositionType } from './estimate-position-type.model';
import { EstimatePosition } from './estimate.model';

export interface WorkType extends BaseModel {
    name: string;
    index: number;
    duration: number;
    isActive: boolean;
    isVisibleInWeb: boolean;
    ordersTypeId: number;
    payoutTypeId: number;
    payoutTypeName: string;
    ordersTypeName: string;
    estimatePositionTypes: EstimatePositionType[];
    estimatePositions: EstimatePosition[];
}
