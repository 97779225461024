import { Component, OnDestroy, OnInit } from '@angular/core';
import { interval, map, Observable, shareReplay, Subscription } from 'rxjs';
import { TimerTime } from './timer-time-model';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { StopWorkTimerModalComponent } from './stop-work-timer-modal/stop-work-timer-modal.component';
import { WorkTimesService } from '@lui/core/services/work-times.service';
import { StartStopWorkTimeRequest } from '@lui/shared/models/start-stop-work-time-request.model';
import { WorkTime } from '@lui/shared/models/work-time.model';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
    selector: 'lui-work-timer',
    templateUrl: './work-timer.component.html',
    styleUrl: './work-timer.component.less',
})
export class WorkTimerComponent implements OnInit, OnDestroy {
    timeSpent$: Observable<TimerTime>;
    activeWorkTime: WorkTime;

    activeWorkTimeChangedSubscription: Subscription;

    constructor(private modalService: NzModalService, private workTimesService: WorkTimesService, private messageService: NzMessageService) {
        this.activeWorkTime = this.workTimesService.activeWorkTime;
        this.timeSpent$ = interval(1000).pipe(
            map((x): TimerTime => this.calculateTimeElapsed(new Date(this.workTimesService.activeWorkTime.startDateTimeUtc))),
            shareReplay(1),
        );
    }

    ngOnInit(): void {
        this.activeWorkTimeChangedSubscription = this.workTimesService.activeWorkTimeChanged.subscribe((newWorkTime: WorkTime) => {
            this.activeWorkTime = newWorkTime;
        });
    }

    async stopTimer(): Promise<void> {
        const modal: NzModalRef<StopWorkTimerModalComponent> = await this.workTimesService.createNotesModal(this.activeWorkTime);
        if (!modal) {
            return;
        }

        modal.afterClose.subscribe(async (notes: string): Promise<void> => {
            if (!notes) {
                return;
            }

            try {
                const stopWorkTimerRequest = {
                    notes: notes,
                    workTimeId: this.activeWorkTime.id,
                } as StartStopWorkTimeRequest;

                await this.workTimesService.stopWorkTimer(stopWorkTimerRequest);
                this.activeWorkTime = null;
                this.workTimesService.setActiveWorkTime(null);
            } catch (ex) {
                console.error(ex);
                if (ex.error?.errors) {
                    ex.error.errors.forEach((error) => {
                        this.messageService.error(error);
                    });
                    return;
                }

                this.messageService.error('Neizdevās pabeigt laika uzskaiti');
                return;
            }
        });
    }

    formatTimePart(timePart: number): string {
        return timePart < 10 ? `0${timePart}` : `${timePart}`;
    }

    ngOnDestroy(): void {
        if (this.activeWorkTimeChangedSubscription) this.activeWorkTimeChangedSubscription.unsubscribe();
    }

    private calculateTimeElapsed(startDate: Date): TimerTime {
        const unixStartDate = startDate.valueOf();

        const millisecondsInSecond: number = 1000;
        const minutesInHour: number = 60;
        const secondsInMinute: number = 60;

        const difference: number = Date.now() - unixStartDate;

        const hoursFromStart: number = Math.floor(difference / (millisecondsInSecond * minutesInHour * secondsInMinute));
        const minutesFromStart: number = Math.floor((difference / (millisecondsInSecond * minutesInHour)) % secondsInMinute);
        const secondsFromStart: number = Math.floor(difference / millisecondsInSecond) % secondsInMinute;

        return { hoursElapsed: hoursFromStart, minutesElapsed: minutesFromStart, secondsElapsed: secondsFromStart } as TimerTime;
    }
}
