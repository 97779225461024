import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Client } from '@lui/shared/models/client.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ClientsService extends BaseService<Client> {
    constructor(protected httpClient: HttpClient) {
        super(httpClient, '/api/v1/clients/');
    }

    isPersonCodeUnique(code: string, id: number): Observable<boolean> {
        return this.httpClient.get<boolean>(`${this.baseApiUrl}${code}/unique/${id}`);
    }
}
